export const StoreKey = 'entityFactory';

// eslint-disable-next-line no-restricted-syntax
export type RequestType = 'create' | 'fetch' | 'fetchAll' | 'update' | 'delete';

// eslint-disable-next-line no-restricted-syntax
export enum RequestEvent {
    start = 'start',
    success = 'success',
    failed = 'failed',
    aborted = 'aborted',
}

// eslint-disable-next-line no-restricted-syntax
export enum RequestMethod {
    GET = 'GET',
    POST = 'POST',
    DELETE = 'DELETE',
}

// eslint-disable-next-line no-restricted-syntax
export enum InternalAction {
    save = 'INTERNAL_SAVE',
}
