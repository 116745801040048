/* eslint-disable default-case */
/* eslint-disable consistent-return */
import React from 'react';

import {StandardSVG, getColorOrDefault} from './util';
import type {IconProps, IconWeight} from './types';

/*
 * Streamline icons import instructions:
 * 1. Go to app.streamlineicons.com and log in with the shared login in the frontend 1Password vault (streamline icons recommend a shared login as of December 2019)
 * 2. Find the icon you'd like to add in the weights you'd like (only add the weights you need)
 * 3. In the Export menu, choose 24px and SVG. Hit download
 * 4. Copy in the SVG code into the template below and:
 *     a) Name the icon component according to its name in streamline. E.g. streamline-icon-alarm-bell@24x24.svg must be named AlarmBell. Icon components must be ordered alphabetically!
 *     b) Replace the svg tag with <StandardSVG className={className} size={size}>...</StandardSVG>
 *     c) Name path properties correctly. E.g. stroke-width should be strokeWidth
 *     d) Replace the fill or stroke of the path with fill={getColorOrDefault(fill)}
 *     e) Always indicate which weights are available using a union type e.g. {weight: Extract<IconWeight, 'Regular' | 'Bold'>} or just {weight: IconWeight} if all are supported
 *
 * Note: If you're adding an icon with more than a single weight, the default weight should be `Regular`. I.e. make sure that the 'default' case in the switch statement
 *       returns the `Regular` version.
 ***/

// TEMPLATE
// export const PascalCaseIconName: React.FC<IconProps & {weight?: Extract<IconWeight, 'Regular'>}> = ({fill, size, className}) => {
//     return (

//     );
// };

export const TrashBin: React.FC<React.PropsWithChildren<IconProps>> = ({fill, size, className}) => (
    <StandardSVG size={size} className={className}>
        <g transform="matrix(1,0,0,1,0,0)">
            <path
                d="M19.5,9H4.5a.5.5,0,0,0-.5.5V22a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V9.49A.5.5,0,0,0,19.5,9Zm-9.25,11.5a.75.75,0,0,1-1.5,0V11.87a.75.75,0,1,1,1.5,0Zm5,0a.75.75,0,0,1-1.5,0V11.87a.75.75,0,1,1,1.5,0Z"
                fill={getColorOrDefault(fill)}
            ></path>
            <path
                d="M20.92,4.85A11.77,11.77,0,0,0,16.8,3.78a4.94,4.94,0,0,0-9.6,0,12.59,12.59,0,0,0-4,1A2,2,0,0,0,2,6.48a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1A1.86,1.86,0,0,0,20.92,4.85ZM12,2A2.89,2.89,0,0,1,14.6,3.58a43,43,0,0,0-5.2,0A2.83,2.83,0,0,1,12,2Z"
                fill={getColorOrDefault(fill)}
            ></path>
        </g>
    </StandardSVG>
);

export const QuestionHelpCircle: React.FC<
    React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Regular'>}>
> = ({fill, size, className}) => (
    <StandardSVG size={size} className={className}>
        <g transform="matrix(1,0,0,1,0,0)">
            <path
                d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm0,19a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,12,19Zm1.6-6.08a1,1,0,0,0-.6.92,1,1,0,0,1-2,0,3,3,0,0,1,1.8-2.75A2,2,0,1,0,10,9.25a1,1,0,0,1-2,0,4,4,0,1,1,5.6,3.67Z"
                fill={getColorOrDefault(fill)}
            ></path>
        </g>
    </StandardSVG>
);

export const AddCircle: React.FC<
    React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Regular' | 'Bold'>}>
> = ({fill, size, className, weight}) => {
    switch (weight) {
        case 'Regular':
            return (
                <StandardSVG size={size} className={className}>
                    <g transform="matrix(1,0,0,1,0,0)">
                        <path
                            d="M12 7.5L12 16.5"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                        ></path>
                        <path
                            d="M7.5 12L16.5 12"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                        ></path>
                        <path
                            d="M0.750 12.000 A11.250 11.250 0 1 0 23.250 12.000 A11.250 11.250 0 1 0 0.750 12.000 Z"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                        ></path>
                    </g>
                </StandardSVG>
            );
        case 'Bold':
        default:
            return (
                <StandardSVG size={size} className={className}>
                    <g transform="matrix(1,0,0,1,0,0)">
                        <path
                            d="M12,23.5A11.5,11.5,0,1,0,.5,12,11.513,11.513,0,0,0,12,23.5ZM6,11h4.75a.25.25,0,0,0,.25-.25V6a1,1,0,0,1,2,0v4.75a.25.25,0,0,0,.25.25H18a1,1,0,0,1,0,2H13.25a.25.25,0,0,0-.25.25V18a1,1,0,0,1-2,0V13.25a.25.25,0,0,0-.25-.25H6a1,1,0,0,1,0-2Z"
                            fill={getColorOrDefault(fill)}
                            stroke="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="0"
                        ></path>
                    </g>
                </StandardSVG>
            );
    }
};

export const AlarmBell: React.FC<
    React.PropsWithChildren<IconProps & {weight: Extract<IconWeight, 'Regular' | 'Bold'>; strokeWidth?: number}>
> = ({fill, size, className, weight, strokeWidth = 1.5}) => {
    switch (weight) {
        case 'Bold':
            return (
                <StandardSVG size={size} className={className}>
                    <g transform="matrix(1,0,0,1,0,0)">
                        <path
                            d="M21,17.5c-0.828,0-1.5-0.672-1.5-1.5v-4.862c0.119-3.924-2.641-7.348-6.5-8.065V1c0-0.552-0.448-1-1-1s-1,0.448-1,1v2.073 C7.141,3.79,4.381,7.214,4.5,11.138V16c0,0.828-0.672,1.5-1.5,1.5c-0.552,0-1,0.448-1,1s0.448,1,1,1h18c0.552,0,1-0.448,1-1 S21.552,17.5,21,17.5z M14.236,21H9.764c-0.127,0-0.234,0.096-0.248,0.222C9.505,21.314,9.5,21.407,9.5,21.5 c0,1.381,1.119,2.5,2.5,2.5s2.5-1.119,2.5-2.5c0-0.093-0.005-0.186-0.016-0.278C14.471,21.095,14.363,20.999,14.236,21z"
                            stroke="none"
                            fill={getColorOrDefault(fill)}
                            strokeWidth="0"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                    </g>
                </StandardSVG>
            );
        case 'Regular':
        default:
            return (
                <StandardSVG size={size} className={className}>
                    <g transform="matrix(1,0,0,1,0,0)">
                        <path
                            d="M 10,21.75c0.325,1.106,1.484,1.739,2.59,1.415c0.682-0.2,1.215-0.733,1.415-1.415 "
                            stroke={getColorOrDefault(fill)}
                            fill="none"
                            strokeWidth={strokeWidth}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M 12,3V0.75 "
                            stroke={getColorOrDefault(fill)}
                            fill="none"
                            strokeWidth={strokeWidth}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M 12,3 c4.142,0,7.5,3.358,7.5,7.5c0,7.046,1.5,8.25,1.5,8.25H3c0,0,1.5-1.916,1.5-8.25C4.5,6.358,7.858,3,12,3z"
                            stroke={getColorOrDefault(fill)}
                            fill="none"
                            strokeWidth={strokeWidth}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                    </g>
                </StandardSVG>
            );
    }
};

export const AlertInformationCircle: React.FC<
    React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Bold'>}>
> = ({fill, size, className}) => {
    return (
        <StandardSVG size={size} className={className}>
            <g transform="matrix(1.7142857142857142,0,0,1.7142857142857142,0,0)">
                <path
                    d="M7,0a7,7,0,1,0,7,7A7.008,7.008,0,0,0,7,0Zm.75,10.5a.75.75,0,0,1-1.5,0V7a.75.75,0,0,1,1.5,0ZM7,5A1,1,0,1,1,8,4,1,1,0,0,1,7,5Z"
                    fill={getColorOrDefault(fill)}
                    stroke="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0"
                ></path>
            </g>
        </StandardSVG>
    );
};

export const ArrowDown: React.FC<React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Bold'>}>> = ({
    fill,
    size,
    className,
}) => (
    <StandardSVG size={size} className={className}>
        <g transform="matrix(1,0,0,1,0,0)">
            <path
                d="M23.292,10.24c-0.586-0.515-1.478-0.459-1.994,0.127l-7.448,8.464c-0.092,0.103-0.251,0.112-0.354,0.02 c-0.053-0.047-0.083-0.115-0.084-0.186V1.413c0-0.78-0.631-1.412-1.411-1.413s-1.412,0.631-1.413,1.411c0,0.001,0,0.001,0,0.002 v17.252c-0.001,0.139-0.114,0.25-0.252,0.25c-0.071,0-0.138-0.031-0.186-0.084L2.7,10.367c-0.494-0.604-1.385-0.692-1.988-0.198 s-0.692,1.385-0.198,1.988c0.022,0.026,0.044,0.052,0.067,0.077L10.233,23.2c0.857,0.976,2.343,1.072,3.319,0.215 c0.076-0.067,0.148-0.139,0.215-0.215l9.652-10.967C23.934,11.648,23.878,10.755,23.292,10.24 C23.292,10.24,23.292,10.24,23.292,10.24z"
                stroke="none"
                fill={getColorOrDefault(fill)}
                strokeWidth="0"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </g>
    </StandardSVG>
);

export const ArrowLeft1: React.FC<React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Bold'>}>> = ({
    fill,
    size,
    className,
}) => (
    <StandardSVG size={size} className={className}>
        <g transform="matrix(1,0,0,1,0,0)">
            <path
                d="M4.5,12c-0.001-0.662,0.283-1.292,0.78-1.729L16.432,0.46c0.781-0.656,1.946-0.554,2.602,0.228 c0.634,0.756,0.562,1.877-0.163,2.545l-9.752,8.579c-0.104,0.091-0.114,0.249-0.023,0.353c0.007,0.008,0.015,0.016,0.023,0.023 l9.752,8.579c0.781,0.656,0.883,1.821,0.228,2.602c-0.656,0.781-1.821,0.883-2.602,0.228c-0.022-0.018-0.043-0.037-0.065-0.057 L5.284,13.732C4.786,13.294,4.501,12.663,4.5,12z"
                stroke="none"
                fill={getColorOrDefault(fill)}
                strokeWidth="0"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </g>
    </StandardSVG>
);

// https://www.streamlinehq.com/icons/legacy-streamline-light?search=arrow
export const ArrowLeft: React.FC<React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Bold'>}>> = ({
    fill,
    size,
    className,
}) => (
    <StandardSVG size={size} className={className}>
        <line
            x1="0.757"
            y1="12"
            x2="23.243"
            y2="12"
            fill="none"
            stroke={getColorOrDefault(fill)}
            strokeLinecap="round"
            strokeLinejoin="round"
        ></line>
        <path
            d="M12.743,1,1.1,11.249a1,1,0,0,0,0,1.5L12.743,23"
            fill="none"
            stroke={getColorOrDefault(fill)}
            strokeLinecap="round"
            strokeLinejoin="round"
        ></path>
    </StandardSVG>
);

export const ArrowUp1: React.FC<React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Bold'>}>> = ({
    fill,
    size,
    className,
}) => {
    return (
        <StandardSVG size={size} className={className}>
            <g transform="matrix(1,0,0,1,0,0)">
                <path
                    d="M12,4.5a2.3,2.3,0,0,1,1.729.78l9.811,11.15a1.847,1.847,0,1,1-2.773,2.439L12.188,9.119a.25.25,0,0,0-.376,0L3.233,18.871A1.847,1.847,0,1,1,.46,16.432L10.268,5.284A2.31,2.31,0,0,1,12,4.5Z"
                    fill={getColorOrDefault(fill)}
                    stroke="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
            </g>
        </StandardSVG>
    );
};

export const ArrowDown1: React.FC<
    React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Regular' | 'Bold'>}>
> = ({fill, size, className, weight}) => {
    switch (weight) {
        case 'Regular':
            return (
                <StandardSVG size={size} className={className}>
                    <g transform="matrix(1,0,0,1,0,0)">
                        <path
                            d="M23.25,7.311,12.53,18.03a.749.749,0,0,1-1.06,0L.75,7.311"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                        ></path>
                    </g>
                </StandardSVG>
            );
        case 'Bold':
        default:
            return (
                <StandardSVG size={size} className={className}>
                    <g transform="matrix(1,0,0,1,0,0)">
                        <path
                            d="M12,19.5a2.3,2.3,0,0,1-1.729-.78L.46,7.568A1.847,1.847,0,0,1,3.233,5.129l8.579,9.752a.25.25,0,0,0,.376,0l8.579-9.752A1.847,1.847,0,1,1,23.54,7.568L13.732,18.716A2.31,2.31,0,0,1,12,19.5Z"
                            fill={getColorOrDefault(fill)}
                            stroke="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                    </g>
                </StandardSVG>
            );
    }
};

export const ArrowRight: React.FC<
    React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Regular' | 'Bold'>}>
> = ({fill, size, className, weight}) => {
    switch (weight) {
        case 'Bold':
            return (
                <StandardSVG size={size} className={className}>
                    <g transform="matrix(2.4,0,0,2.4,0,0)">
                        <path
                            d="M7.5,4.1l-4.1-3.9A.75.75,0,1,0,2.36,1.294L6.072,4.819a.249.249,0,0,1,0,.362L2.36,8.706A.75.75,0,0,0,3.393,9.794L7.51,5.884A1.256,1.256,0,0,0,7.5,4.1Z"
                            fill={getColorOrDefault(fill)}
                            stroke="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="0"
                        ></path>
                    </g>
                </StandardSVG>
            );
        case 'Regular':
        default:
            return (
                <StandardSVG size={size} className={className}>
                    <g transform="matrix(1,0,0,1,0,0)">
                        <path
                            d="M 5.5,0.75l10.72,10.72c0.293,0.292,0.293,0.767,0.001,1.059c0,0-0.001,0.001-0.001,0.001L5.5,23.25"
                            stroke={getColorOrDefault(fill)}
                            fill="none"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                    </g>
                </StandardSVG>
            );
    }
};

export const ArrowUp: React.FC<React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Bold'>}>> = ({
    fill,
    size,
    className,
}) => {
    return (
        <StandardSVG size={size} className={className}>
            <g transform="matrix(1,0,0,1,0,0)">
                <path
                    d="M23.421,11.765,13.768.8A2.641,2.641,0,0,0,12,0a2.645,2.645,0,0,0-1.768.8L.579,11.765A1.413,1.413,0,1,0,2.7,13.632l7.45-8.466a.25.25,0,0,1,.437.166V22.587a1.413,1.413,0,1,0,2.826,0V5.332a.25.25,0,0,1,.438-.165L21.3,13.632a1.413,1.413,0,1,0,2.121-1.867Z"
                    fill={getColorOrDefault(fill)}
                    stroke="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0"
                />
            </g>
        </StandardSVG>
    );
};

export const BookOpen: React.FC<React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Bold'>}>> = ({
    fill,
    size,
    className,
}) => {
    return (
        <StandardSVG size={size} className={className}>
            <g transform="matrix(1,0,0,1,0,0)">
                <path
                    d="M23.391,1.568a1.959,1.959,0,0,0-1.438-.56c-3.841.114-7.707.868-9.656,2.308a.5.5,0,0,1-.594,0C9.754,1.876,5.888,1.122,2.047,1.008a1.951,1.951,0,0,0-1.438.56A2.021,2.021,0,0,0,0,3.009v14.5a2,2,0,0,0,1.956,2C7.923,19.678,11,21.327,11,21.993a1,1,0,0,0,2,0c0-.666,3.077-2.315,9.044-2.486a2,2,0,0,0,1.956-2V3.009A2.021,2.021,0,0,0,23.391,1.568ZM2.478,17.526a.5.5,0,0,1-.478-.5l-.012-13.5a.5.5,0,0,1,.523-.5C8.113,3.281,11,4.849,11,5.493V18.578a.5.5,0,0,1-.7.458A22.937,22.937,0,0,0,2.478,17.526Zm19.509-.5a.5.5,0,0,1-.479.5A22.909,22.909,0,0,0,13.7,19.036a.5.5,0,0,1-.7-.458V5.493c0-.643,2.886-2.211,8.477-2.464a.5.5,0,0,1,.523.5Z"
                    fill={getColorOrDefault(fill)}
                    stroke="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0"
                ></path>
            </g>
        </StandardSVG>
    );
};

export const BookBookmark: React.FC<React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Regular'>}>> = ({
    fill,
    size,
    className,
}) => {
    return (
        <StandardSVG size={size} className={className}>
            <g transform="matrix(1,0,0,1,0,0)">
                <path
                    d="M12 4.057L12 21.739"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                ></path>
                <path
                    d="M15.49,3.184a16.546,16.546,0,0,0-2.947.782,1.61,1.61,0,0,1-1.085,0,13.881,13.881,0,0,0-9.587-.2A1.607,1.607,0,0,0,.75,5.3V19.637A1.607,1.607,0,0,0,2.756,21.2a13.663,13.663,0,0,1,8.7.448,1.6,1.6,0,0,0,1.085,0,13.66,13.66,0,0,1,8.7-.448,1.608,1.608,0,0,0,2.006-1.559V5.3A1.607,1.607,0,0,0,22.129,3.77a16.908,16.908,0,0,0-2.365-.576"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                ></path>
                <path
                    d="M19.875,11.261l-2.25-2.25-2.25,2.25v-7.5a1.5,1.5,0,0,1,1.5-1.5h1.5a1.5,1.5,0,0,1,1.5,1.5Z"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                ></path>
            </g>
        </StandardSVG>
    );
};

export const BookOpenBookmark: React.FC<
    React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Regular'>; strokeWidth?: number}>
> = ({fill, size, className, strokeWidth = 1.5}) => {
    return (
        <StandardSVG size={size} className={className}>
            <g transform="matrix(1,0,0,1,0,0)">
                <path
                    d="M12,22.8V5.387S9.545,2.66,1.268,2.55A.514.514,0,0,0,.9,2.7a.526.526,0,0,0-.152.37V19.444a.516.516,0,0,0,.5.52C9.543,20.071,12,22.8,12,22.8"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={strokeWidth}
                ></path>
                <path
                    d="M9,11.855A21.258,21.258,0,0,0,3.74,10.8"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={strokeWidth}
                ></path>
                <path
                    d="M9,16.05A21.311,21.311,0,0,0,3.74,15"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={strokeWidth}
                ></path>
                <path
                    d="M15,11.855A21.258,21.258,0,0,1,20.26,10.8"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={strokeWidth}
                ></path>
                <path
                    d="M15,16.05A21.311,21.311,0,0,1,20.26,15"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={strokeWidth}
                ></path>
                <path
                    d="M20.26,2.67c.76-.066,1.582-.108,2.472-.12a.514.514,0,0,1,.366.15.526.526,0,0,1,.152.37V19.444a.516.516,0,0,1-.5.52C14.457,20.071,12,22.8,12,22.8"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={strokeWidth}
                ></path>
                <path
                    d="M12,22.8V5.387s1.143-1.27,4.5-2.111"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={strokeWidth}
                ></path>
                <path
                    d="M20.25,7.8V1.2a24.031,24.031,0,0,0-3.75.6v6l1.875-1.5Z"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={strokeWidth}
                ></path>
            </g>
        </StandardSVG>
    );
};

export const Bin: React.FC<React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Bold'>}>> = ({
    fill,
    size,
    className,
}) => {
    return (
        <StandardSVG size={size} className={className}>
            <g transform="matrix(1,0,0,1,0,0)">
                <path
                    d="M19.5,7.5H4.5A.5.5,0,0,0,4,8V22a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V8A.5.5,0,0,0,19.5,7.5Zm-9.25,13a.75.75,0,0,1-1.5,0v-9a.75.75,0,0,1,1.5,0Zm5,0a.75.75,0,0,1-1.5,0v-9a.75.75,0,0,1,1.5,0Z"
                    fill={getColorOrDefault(fill)}
                    stroke="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0"
                ></path>
                <path
                    d="M22,4H17.25A.25.25,0,0,1,17,3.75V2.5A2.5,2.5,0,0,0,14.5,0h-5A2.5,2.5,0,0,0,7,2.5V3.75A.25.25,0,0,1,6.75,4H2A1,1,0,0,0,2,6H22a1,1,0,0,0,0-2ZM9,3.75V2.5A.5.5,0,0,1,9.5,2h5a.5.5,0,0,1,.5.5V3.75a.25.25,0,0,1-.25.25H9.25A.25.25,0,0,1,9,3.75Z"
                    fill={getColorOrDefault(fill)}
                    stroke="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0"
                ></path>
            </g>
        </StandardSVG>
    );
};

export const Calendar: React.FC<
    React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Regular' | 'Bold'>}>
> = ({fill, size, className, weight = 'Bold'}) => {
    switch (weight) {
        case 'Bold':
            return (
                <StandardSVG size={size} className={className}>
                    <g transform="matrix(1,0,0,1,0,0)">
                        <path
                            d="M21.5,3h-2.75c-0.138,0-0.25-0.112-0.25-0.25V1c0-0.552-0.448-1-1-1s-1,0.448-1,1v4.75c0,0.414-0.336,0.75-0.75,0.75l0,0 C15.336,6.5,15,6.164,15,5.75V3.5C15,3.224,14.776,3,14.5,3H8.25C8.112,3,8.001,2.889,8,2.751V1c0-0.552-0.448-1-1-1S6,0.448,6,1 v4.75C6,6.164,5.664,6.5,5.25,6.5l0,0C4.836,6.5,4.5,6.164,4.5,5.75V3.5C4.5,3.224,4.276,3,4,3H2.5c-1.105,0-2,0.895-2,2v17 c0,1.105,0.895,2,2,2h19c1.105,0,2-0.895,2-2V5C23.5,3.895,22.605,3,21.5,3z M21,22H3c-0.276,0-0.5-0.224-0.5-0.5v-12 C2.5,9.224,2.724,9,3,9h18c0.276,0,0.5,0.224,0.5,0.5v12C21.5,21.776,21.276,22,21,22z"
                            stroke="none"
                            fill={getColorOrDefault(fill)}
                            strokeWidth="0"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                    </g>
                </StandardSVG>
            );
        case 'Regular':
        default:
            return (
                <StandardSVG size={size} className={className}>
                    <g transform="matrix(1,0,0,1,0,0)">
                        <path
                            d="M 2.252,3.75h19.5c0.828,0,1.5,0.672,1.5,1.5v16.5c0,0.828-0.672,1.5-1.5,1.5h-19.5c-0.828,0-1.5-0.672-1.5-1.5 V5.25C0.752,4.422,1.424,3.75,2.252,3.75z "
                            stroke={getColorOrDefault(fill)}
                            fill="none"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M 0.752,9.75h22.5 "
                            stroke={getColorOrDefault(fill)}
                            fill="none"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M 6.752,6V0.75 "
                            stroke={getColorOrDefault(fill)}
                            fill="none"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M 17.252,6V0.75"
                            stroke={getColorOrDefault(fill)}
                            fill="none"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                    </g>
                </StandardSVG>
            );
    }
};

export const Check1: React.FC<
    React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Regular' | 'Bold'>}>
> = ({fill, size, className, weight}) => {
    switch (weight) {
        case 'Bold':
            return (
                <StandardSVG size={size} className={className}>
                    <g transform="matrix(1,0,0,1,0,0)">
                        <path
                            d="M23.374.287a1.5,1.5,0,0,0-2.093.345L7.246,20.2,2.561,15.511A1.5,1.5,0,1,0,.439,17.632l5.935,5.934a1.525,1.525,0,0,0,2.279-.186l15.066-21A1.5,1.5,0,0,0,23.374.287Z"
                            fill={getColorOrDefault(fill)}
                            stroke="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="0"
                        ></path>
                    </g>
                </StandardSVG>
            );
        case 'Regular':
        default:
            return (
                <StandardSVG size={size} className={className}>
                    <g transform="matrix(1,0,0,1,0,0)">
                        <path
                            d="M 23.25,0.749L8.158,22.308c-0.694,0.997-2.065,1.243-3.062,0.549c-0.194-0.135-0.365-0.301-0.507-0.49 L0.75,17.249"
                            stroke={getColorOrDefault(fill)}
                            fill="none"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </g>
                </StandardSVG>
            );
    }
};

export const CheckCircle: React.FC<React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Regular'>}>> = ({
    fill,
    size,
    className,
}) => (
    <StandardSVG size={size} className={className}>
        <g transform="matrix(1,0,0,1,0,0)">
            <path
                d="M 6.75,9l3.294,4.611c0.49,0.686,1.443,0.845,2.129,0.355c0.106-0.076,0.202-0.165,0.285-0.265L23.25,0.749 "
                stroke={getColorOrDefault(fill)}
                fill="none"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M 16.783,3.824C11.858,0.776,5.395,2.298,2.347,7.223S0.821,18.611,5.746,21.659s11.388,1.526,14.436-3.399 c1.846-2.984,2.078-6.693,0.618-9.883"
                stroke={getColorOrDefault(fill)}
                fill="none"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </g>
    </StandardSVG>
);

export const CheckCircle1Alternate: React.FC<
    React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Bold'>}>
> = ({fill, size, className}) => (
    <StandardSVG size={size} className={className}>
        <g transform="matrix(1,0,0,1,0,0)">
            <path
                d="M18.48,6.449c-0.555-0.409-1.337-0.291-1.746,0.264c0,0,0,0-0.001,0.001l-5.924,8.04L7.042,11.74 c-0.548-0.42-1.334-0.316-1.754,0.232c-0.408,0.532-0.323,1.291,0.191,1.721l4.783,3.826c0.549,0.43,1.343,0.333,1.773-0.217 c0.005-0.006,0.009-0.012,0.014-0.018l6.7-9.087C19.157,7.64,19.037,6.858,18.48,6.449C18.481,6.449,18.48,6.449,18.48,6.449z M12,0C5.373,0,0,5.373,0,12s5.373,12,12,12s12-5.373,12-12C23.993,5.376,18.624,0.007,12,0z M12,22C6.477,22,2,17.523,2,12 S6.477,2,12,2s10,4.477,10,10C21.994,17.52,17.52,21.994,12,22z"
                stroke="none"
                fill={getColorOrDefault(fill)}
                strokeWidth="0"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </g>
    </StandardSVG>
);

export const Cog1: React.FC<
    React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Regular' | 'Bold'>}>
> = ({fill, size, className, weight}) => {
    switch (weight) {
        case 'Bold':
            return (
                <StandardSVG size={size} className={className}>
                    <g transform="matrix(1,0,0,1,0,0)">
                        <path
                            d="M22.421,9.763l-1.266-0.449c-0.715-0.254-1.089-1.04-0.834-1.755c0.016-0.045,0.034-0.088,0.054-0.131l0,0l0.576-1.213 c0.562-1.186,0.057-2.603-1.129-3.165c-0.644-0.306-1.392-0.306-2.036,0l-1.213,0.577c-0.686,0.325-1.506,0.033-1.832-0.653 c-0.02-0.042-0.038-0.085-0.053-0.129l-0.45-1.265c-0.441-1.236-1.8-1.881-3.036-1.44c-0.672,0.24-1.201,0.768-1.44,1.44 l-0.45,1.266C9.057,3.561,8.271,3.935,7.555,3.68C7.512,3.665,7.469,3.647,7.427,3.627L6.214,3.05 C5.028,2.488,3.611,2.993,3.049,4.179c-0.306,0.644-0.306,1.392,0,2.036l0.576,1.213C3.951,8.114,3.66,8.934,2.974,9.26 C2.932,9.28,2.889,9.298,2.845,9.314l-1.266,0.45c-1.236,0.44-1.881,1.798-1.441,3.034C0.377,13.47,0.906,14,1.579,14.239 l1.266,0.45c0.715,0.254,1.088,1.04,0.834,1.755c-0.016,0.044-0.034,0.088-0.054,0.13l-0.576,1.213 c-0.562,1.186-0.057,2.603,1.129,3.165c0.644,0.306,1.392,0.306,2.036,0l1.213-0.576c0.685-0.326,1.504-0.036,1.83,0.649 c0.021,0.043,0.039,0.087,0.055,0.132l0.45,1.265c0.441,1.236,1.8,1.881,3.036,1.44c0.672-0.24,1.201-0.768,1.44-1.44l0.45-1.266 c0.254-0.715,1.04-1.088,1.755-0.834c0.044,0.016,0.088,0.034,0.13,0.054l1.213,0.576c1.186,0.562,2.603,0.057,3.165-1.129 c0.306-0.644,0.306-1.392,0-2.036l-0.576-1.213c-0.326-0.685-0.035-1.505,0.65-1.831c0.042-0.02,0.086-0.038,0.13-0.054 l1.266-0.451c1.236-0.44,1.881-1.798,1.441-3.034C23.623,10.532,23.094,10.002,22.421,9.763z M12,16.785 c-1.929-0.028-3.664-1.178-4.441-2.944c-1.013-2.452,0.15-5.262,2.6-6.281c2.452-0.981,5.239,0.173,6.281,2.6 c1.013,2.452-0.15,5.262-2.6,6.281C13.257,16.681,12.63,16.798,12,16.785z"
                            stroke="none"
                            fill={getColorOrDefault(fill)}
                            strokeWidth="0"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                    </g>
                </StandardSVG>
            );
        case 'Regular':
        default:
            return (
                <StandardSVG size={size} className={className}>
                    <g transform="matrix(1,0,0,1,0,0)">
                        <path
                            d="M10.546,2.438a1.957,1.957,0,0,0,2.908,0L14.4,1.4a1.959,1.959,0,0,1,3.41,1.413l-.071,1.4A1.958,1.958,0,0,0,19.79,6.267l1.4-.071A1.959,1.959,0,0,1,22.6,9.606l-1.042.94a1.96,1.96,0,0,0,0,2.909l1.042.94a1.959,1.959,0,0,1-1.413,3.41l-1.4-.071a1.958,1.958,0,0,0-2.056,2.056l.071,1.4A1.959,1.959,0,0,1,14.4,22.6l-.941-1.041a1.959,1.959,0,0,0-2.908,0L9.606,22.6A1.959,1.959,0,0,1,6.2,21.192l.072-1.4a1.958,1.958,0,0,0-2.056-2.056l-1.4.071A1.958,1.958,0,0,1,1.4,14.4l1.041-.94a1.96,1.96,0,0,0,0-2.909L1.4,9.606A1.958,1.958,0,0,1,2.809,6.2l1.4.071A1.958,1.958,0,0,0,6.267,4.211L6.2,2.81A1.959,1.959,0,0,1,9.606,1.4Z"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                        ></path>
                        <path
                            d="M7.500 12.001 A4.500 4.500 0 1 0 16.500 12.001 A4.500 4.500 0 1 0 7.500 12.001 Z"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                        ></path>
                    </g>
                </StandardSVG>
            );
    }
};

export const CommonFileText: React.FC<
    React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Regular' | 'Bold'>}>
> = ({fill, size, className, weight}) => {
    switch (weight) {
        case 'Regular':
            return (
                <StandardSVG size={size} className={className}>
                    <g transform="matrix(1,0,0,1,0,0)">
                        <path
                            d="M22.5,21.75a1.5,1.5,0,0,1-1.5,1.5H3a1.5,1.5,0,0,1-1.5-1.5V2.25A1.5,1.5,0,0,1,3,.75H18.045a1.5,1.5,0,0,1,1.048.426l2.954,2.883A1.5,1.5,0,0,1,22.5,5.133Z"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                        />
                        <path
                            d="M6.045 8.25L18.045 8.25"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                        />
                        <path
                            d="M6.045 12.75L18.045 12.75"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                        />
                        <path
                            d="M6.045 17.25L12.045 17.25"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                        />
                    </g>
                </StandardSVG>
            );
        case 'Bold':
        default:
            return (
                <StandardSVG size={size} className={className}>
                    <g transform="matrix(1,0,0,1,0,0)">
                        <path
                            d="M21.414,4.914,17.086.586A2,2,0,0,0,15.672,0H4A2,2,0,0,0,2,2V22a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V6.328A2,2,0,0,0,21.414,4.914ZM11,19.5H6.5a1,1,0,0,1,0-2H11a1,1,0,0,1,0,2Zm4.5-4h-9a1,1,0,0,1,0-2h9a1,1,0,0,1,0,2Zm0-4h-9a1,1,0,0,1,0-2h9a1,1,0,0,1,0,2Z"
                            fill={getColorOrDefault(fill)}
                            stroke="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="0"
                        ></path>
                    </g>
                </StandardSVG>
            );
    }
};

export const Close: React.FC<
    React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Regular' | 'Bold'>}>
> = ({fill, size, className, weight}) => {
    switch (weight) {
        case 'Bold':
            return (
                <StandardSVG size={size} className={className}>
                    <g transform="matrix(1,0,0,1,0,0)">
                        <path
                            d="M14.3,12.179c-0.098-0.098-0.098-0.256,0-0.354c0,0,0,0,0,0l9.263-9.262c0.586-0.587,0.585-1.537-0.002-2.123 s-1.537-0.585-2.122,0.002L12.177,9.7c-0.098,0.098-0.256,0.098-0.354,0c0,0,0,0,0,0L2.561,0.442 C1.975-0.144,1.025-0.144,0.44,0.441s-0.586,1.536-0.001,2.122L9.7,11.825c0.098,0.098,0.098,0.256,0,0.354c0,0,0,0,0,0 l-9.261,9.263c-0.586,0.586-0.585,1.536,0,2.122s1.536,0.585,2.122,0l0,0l9.262-9.263c0.098-0.098,0.256-0.098,0.354,0c0,0,0,0,0,0 l9.262,9.263c0.586,0.586,1.536,0.586,2.122,0s0.586-1.536,0-2.122L14.3,12.179z"
                            stroke="none"
                            fill={getColorOrDefault(fill)}
                            strokeWidth="0"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                    </g>
                </StandardSVG>
            );

        case 'Regular':
        default:
            return (
                <StandardSVG size={size} className={className}>
                    <g transform="matrix(1,0,0,1,0,0)">
                        <path
                            d="M 0.75,23.249l22.5-22.5 "
                            stroke={getColorOrDefault(fill)}
                            fill="none"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M 23.25,23.249l-22.5-22.5"
                            stroke={getColorOrDefault(fill)}
                            fill="none"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                    </g>
                </StandardSVG>
            );
    }
};

export const Expand3: React.FunctionComponent<
    React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Regular'>}>
> = ({fill, size, className}) => {
    return (
        <StandardSVG size={size} className={className}>
            <g transform="matrix(1,0,0,1,0,0)">
                <path
                    d="M9.75 14.248L0.75 23.248"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                ></path>
                <path
                    d="M23.25 7.498L23.25 0.748 16.5 0.748"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                ></path>
                <path
                    d="M0.75 16.498L0.75 23.248 7.5 23.248"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                ></path>
                <path
                    d="M23.25 0.748L14.25 9.748"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                ></path>
            </g>
        </StandardSVG>
    );
};

export const Filter: React.FunctionComponent<
    React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Bold'>}>
> = ({fill, size, className}) => {
    return (
        <StandardSVG size={size} className={className}>
            <g transform="matrix(1,0,0,1,0,0)">
                <path
                    d="M23.894.552A1,1,0,0,0,23,0H1A1,1,0,0,0,.2,1.6L9,13.423V22a2.015,2.015,0,0,0,2,2,1.993,1.993,0,0,0,1.2-.4l2-1.5a2.007,2.007,0,0,0,.8-1.6V13.423L23.8,1.6A1,1,0,0,0,23.894.552ZM5.417,2.2l3.939,5.25a.5.5,0,0,1,.1.3V9a.5.5,0,0,1-.9.3L3.62,2.8a.5.5,0,0,1,.4-.8h1A.5.5,0,0,1,5.417,2.2Z"
                    fill={getColorOrDefault(fill)}
                    stroke="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0"
                ></path>
            </g>
        </StandardSVG>
    );
};

export const FilterText1: React.FC<React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Bold'>}>> = ({
    fill,
    size,
    className,
}) => {
    return (
        <StandardSVG size={size} className={className}>
            <g transform="matrix(1,0,0,1,0,0)">
                <path
                    d="M22,0H5.5a2,2,0,0,0-2,2V18.5a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2V2A2,2,0,0,0,22,0ZM14.5,15a.5.5,0,0,1-.5.5H9a.5.5,0,0,1-.5-.5V14a.5.5,0,0,1,.5-.5h5a.5.5,0,0,1,.5.5Zm5-4.5a.5.5,0,0,1-.5.5H9a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,9,9H19a.5.5,0,0,1,.5.5Zm0-4.5a.5.5,0,0,1-.5.5H9A.5.5,0,0,1,8.5,6V5A.5.5,0,0,1,9,4.5H19a.5.5,0,0,1,.5.5Z"
                    fill={getColorOrDefault(fill)}
                    stroke="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0"
                ></path>
                <path
                    d="M19.5,22H2.5a.5.5,0,0,1-.5-.5V4.5a1,1,0,0,0-2,0V22a2,2,0,0,0,2,2H19.5a1,1,0,0,0,0-2Z"
                    fill={getColorOrDefault(fill)}
                    stroke="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0"
                ></path>
            </g>
        </StandardSVG>
    );
};

export const HelpWheel: React.FC<React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Regular'>}>> = ({
    fill,
    size,
    className,
}) => {
    return (
        <StandardSVG size={size} className={className}>
            <g>
                <circle
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5px"
                    cx={12}
                    cy={12}
                    r={3.75}
                />
                <path
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5px"
                    d="m17.119 20.301-4.59-4.59M15.711 12.53l4.59 4.589M3.699 17.119l4.59-4.59M11.471 15.712l-4.59 4.589M6.881 3.7l4.59 4.589M8.289 11.471l-4.59-4.59M20.301 6.881l-4.59 4.59M12.529 8.289l4.59-4.589"
                />
                <circle
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5px"
                    cx={12}
                    cy={12}
                    r={9.75}
                />
            </g>
        </StandardSVG>
    );
};

export const House: React.FC<
    React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Regular' | 'Bold'>; strokeWidth?: number}>
> = ({fill, size, className, weight, strokeWidth = 1.5}) => {
    switch (weight) {
        case 'Bold':
            return (
                <StandardSVG size={size} className={className}>
                    <g transform="matrix(1,0,0,1,0,0)">
                        <path
                            d="M23.707,11.293l-11-11a1,1,0,0,0-1.414,0l-11,11A1,1,0,0,0,1,13H2.25a.25.25,0,0,1,.25.25V23a1,1,0,0,0,1,1H9.25a.25.25,0,0,0,.25-.25V19a2.5,2.5,0,0,1,5,0v4.75a.25.25,0,0,0,.25.25H20.5a1,1,0,0,0,1-1V13.25a.25.25,0,0,1,.25-.25H23a1,1,0,0,0,.707-1.707Z"
                            fill={getColorOrDefault(fill)}
                            stroke="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="0"
                        ></path>
                    </g>
                </StandardSVG>
            );
        case 'Regular':
        default:
            return (
                <StandardSVG size={size} className={className}>
                    <g transform="matrix(1,0,0,1,0,0)">
                        <path
                            d="M3.753,13.944v8.25h6v-6a1.5,1.5,0,0,1,1.5-1.5h1.5a1.5,1.5,0,0,1,1.5,1.5v6h6v-8.25"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={strokeWidth}
                        ></path>
                        <path
                            d="M.753,12.444,10.942,2.255a1.5,1.5,0,0,1,2.122,0L23.253,12.444"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={strokeWidth}
                        ></path>
                    </g>
                </StandardSVG>
            );
    }
};

export const HyperlinkCircle: React.FunctionComponent<
    React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Regular'>}>
> = ({fill, size, className}) => {
    return (
        <StandardSVG size={size} className={className}>
            <g transform="matrix(1,0,0,1,0,0)">
                <path
                    d="M 12.655,17.993c-1.074,2.265-3.775,3.239-6.047,2.178l0,0c-2.265-1.074-3.239-3.775-2.178-6.047l1.382-2.937 c1.073-2.265,3.775-3.238,6.046-2.178l0,0c1.292,0.61,2.222,1.792,2.511,3.191 "
                    stroke={getColorOrDefault(fill)}
                    fill="none"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M 11.846,6.107c1.074-2.265,3.775-3.239,6.047-2.178 l0,0c2.265,1.074,3.238,3.775,2.178,6.046l-1.382,2.937c-1.074,2.265-3.775,3.239-6.047,2.178l0,0 c-1.105-0.521-1.953-1.465-2.352-2.619"
                    stroke={getColorOrDefault(fill)}
                    fill="none"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
            </g>
        </StandardSVG>
    );
};

export const InformationCircle: React.FunctionComponent<
    React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Regular'>; strokeWidth?: number}>
> = ({fill, size, className, strokeWidth = 1.5}) => {
    return (
        <StandardSVG size={size} className={className}>
            <g transform="matrix(1,0,0,1,0,0)">
                <path
                    d="M14.25,16.5H13.5A1.5,1.5,0,0,1,12,15V11.25a.75.75,0,0,0-.75-.75H10.5"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeWidth={strokeWidth}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M11.625,6.75A.375.375,0,1,0,12,7.125a.375.375,0,0,0-.375-.375h0"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeWidth={strokeWidth}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M0.750 12.000 A11.250 11.250 0 1 0 23.250 12.000 A11.250 11.250 0 1 0 0.750 12.000 Z"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeWidth={strokeWidth}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
            </g>
        </StandardSVG>
    );
};

export const InterfaceAlertWarningCircle: React.FC<
    React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Regular'>}>
> = ({fill, size, className}) => {
    return (
        <StandardSVG size={size} className={className}>
            <g transform="matrix(1.7142857142857142,0,0,1.7142857142857142,0,0)">
                <circle
                    cx="7"
                    cy="7"
                    r="6.5"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></circle>
                <line
                    x1="7"
                    y1="3.5"
                    x2="7"
                    y2="6.5"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></line>
                <circle
                    cx="7"
                    cy="9.5"
                    r="0.5"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></circle>
            </g>
        </StandardSVG>
    );
};

export const InterfaceSettingMenuHorizontal: React.FC<
    React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Bold'>}>
> = ({fill, size, className}) => {
    return (
        <StandardSVG size={size} className={className}>
            <g transform="matrix(2.4,0,0,2.4,0,0)">
                <path
                    d="M7.5 5A1.25 1.25 0 1 0 10 5a1.25 1.25 0 1 0-2.5 0zM3.75 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 1 0-2.5 0zM0 5a1.25 1.25 0 1 0 2.5 0A1.25 1.25 0 1 0 0 5z"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0"
                    fill={getColorOrDefault(fill)}
                ></path>
            </g>
        </StandardSVG>
    );
};

export const LayoutModule: React.FC<
    React.PropsWithChildren<IconProps & {weight: Extract<IconWeight, 'Regular' | 'Bold'>}>
> = ({fill, size, className, weight}) => {
    switch (weight) {
        case 'Bold':
            return (
                <StandardSVG size={size} className={className}>
                    <g transform="matrix(1,0,0,1,0,0)">
                        <path
                            d="M1.75,0.247h3.5c0.828,0,1.5,0.672,1.5,1.5v3.5c0,0.828-0.672,1.5-1.5,1.5h-3.5c-0.828,0-1.5-0.672-1.5-1.5v-3.5 C0.25,0.919,0.922,0.247,1.75,0.247z M1.75,8.747h3.5c0.828,0,1.5,0.672,1.5,1.5v3.5c0,0.828-0.672,1.5-1.5,1.5h-3.5 c-0.828,0-1.5-0.672-1.5-1.5v-3.5C0.25,9.419,0.922,8.747,1.75,8.747z M1.75,17.247h3.5c0.828,0,1.5,0.672,1.5,1.5v3.5 c0,0.828-0.672,1.5-1.5,1.5h-3.5c-0.828,0-1.5-0.672-1.5-1.5v-3.5C0.25,17.919,0.922,17.247,1.75,17.247z M10.25,0.247h3.5 c0.828,0,1.5,0.672,1.5,1.5v3.5c0,0.828-0.672,1.5-1.5,1.5h-3.5c-0.828,0-1.5-0.672-1.5-1.5v-3.5 C8.75,0.919,9.422,0.247,10.25,0.247z M10.25,8.747h3.5c0.828,0,1.5,0.672,1.5,1.5v3.5c0,0.828-0.672,1.5-1.5,1.5h-3.5 c-0.828,0-1.5-0.672-1.5-1.5v-3.5C8.75,9.419,9.422,8.747,10.25,8.747z M10.25,17.247h3.5c0.828,0,1.5,0.672,1.5,1.5v3.5 c0,0.828-0.672,1.5-1.5,1.5h-3.5c-0.828,0-1.5-0.672-1.5-1.5v-3.5C8.75,17.919,9.422,17.247,10.25,17.247z M18.75,0.247h3.5 c0.828,0,1.5,0.672,1.5,1.5v3.5c0,0.828-0.672,1.5-1.5,1.5h-3.5c-0.828,0-1.5-0.672-1.5-1.5v-3.5 C17.25,0.919,17.922,0.247,18.75,0.247z M18.75,8.747h3.5c0.828,0,1.5,0.672,1.5,1.5v3.5c0,0.828-0.672,1.5-1.5,1.5h-3.5 c-0.828,0-1.5-0.672-1.5-1.5v-3.5C17.25,9.419,17.922,8.747,18.75,8.747z M18.75,17.247h3.5c0.828,0,1.5,0.672,1.5,1.5v3.5 c0,0.828-0.672,1.5-1.5,1.5h-3.5c-0.828,0-1.5-0.672-1.5-1.5v-3.5C17.25,17.919,17.922,17.247,18.75,17.247z"
                            stroke="none"
                            fill={getColorOrDefault(fill)}
                            strokeWidth="0"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                    </g>
                </StandardSVG>
            );
        case 'Regular':
        default:
            return (
                <StandardSVG size={size} className={className}>
                    <g transform="matrix(1,0,0,1,0,0)">
                        <path
                            d="M 2.25,0.747h1.5c0.828,0,1.5,0.672,1.5,1.5v1.5c0,0.828-0.672,1.5-1.5,1.5h-1.5c-0.828,0-1.5-0.672-1.5-1.5 v-1.5C0.75,1.419,1.422,0.747,2.25,0.747z "
                            stroke={getColorOrDefault(fill)}
                            fill="none"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M 11.25,0.747h1.5c0.828,0,1.5,0.672,1.5,1.5v1.5c0,0.828-0.672,1.5-1.5,1.5h-1.5 c-0.828,0-1.5-0.672-1.5-1.5v-1.5C9.75,1.419,10.422,0.747,11.25,0.747z "
                            stroke={getColorOrDefault(fill)}
                            fill="none"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M 20.25,0.747h1.5c0.828,0,1.5,0.672,1.5,1.5v1.5 c0,0.828-0.672,1.5-1.5,1.5h-1.5c-0.828,0-1.5-0.672-1.5-1.5v-1.5C18.75,1.419,19.422,0.747,20.25,0.747z "
                            stroke={getColorOrDefault(fill)}
                            fill="none"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M 2.25,9.747h1.5 c0.828,0,1.5,0.672,1.5,1.5v1.5c0,0.828-0.672,1.5-1.5,1.5h-1.5c-0.828,0-1.5-0.672-1.5-1.5v-1.5 C0.75,10.419,1.422,9.747,2.25,9.747z "
                            stroke={getColorOrDefault(fill)}
                            fill="none"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M 11.25,9.747h1.5c0.828,0,1.5,0.672,1.5,1.5v1.5c0,0.828-0.672,1.5-1.5,1.5h-1.5 c-0.828,0-1.5-0.672-1.5-1.5v-1.5C9.75,10.419,10.422,9.747,11.25,9.747z "
                            stroke={getColorOrDefault(fill)}
                            fill="none"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M 20.25,9.747h1.5c0.828,0,1.5,0.672,1.5,1.5v1.5 c0,0.828-0.672,1.5-1.5,1.5h-1.5c-0.828,0-1.5-0.672-1.5-1.5v-1.5C18.75,10.419,19.422,9.747,20.25,9.747z "
                            stroke={getColorOrDefault(fill)}
                            fill="none"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M 2.25,18.747h1.5 c0.828,0,1.5,0.672,1.5,1.5v1.5c0,0.828-0.672,1.5-1.5,1.5h-1.5c-0.828,0-1.5-0.672-1.5-1.5v-1.5 C0.75,19.419,1.422,18.747,2.25,18.747z "
                            stroke={getColorOrDefault(fill)}
                            fill="none"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M 11.25,18.747h1.5c0.828,0,1.5,0.672,1.5,1.5v1.5c0,0.828-0.672,1.5-1.5,1.5h-1.5 c-0.828,0-1.5-0.672-1.5-1.5v-1.5C9.75,19.419,10.422,18.747,11.25,18.747z "
                            stroke={getColorOrDefault(fill)}
                            fill="none"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M 20.25,18.747h1.5c0.828,0,1.5,0.672,1.5,1.5v1.5 c0,0.828-0.672,1.5-1.5,1.5h-1.5c-0.828,0-1.5-0.672-1.5-1.5v-1.5C18.75,19.419,19.422,18.747,20.25,18.747z"
                            stroke={getColorOrDefault(fill)}
                            fill="none"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                    </g>
                </StandardSVG>
            );
    }
};

// Regular: https://app.streamlinehq.com/icons/streamline-regular/interface-essential/login-logout/login-2
export const Login2: React.FC<
    React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Regular' | 'Bold'>; strokeWidth?: number}>
> = ({fill, size, className, weight, strokeWidth = 1.5}) => {
    switch (weight) {
        case 'Regular':
            return (
                <StandardSVG size={size} className={className}>
                    <g transform="matrix(1,0,0,1,0,0)">
                        <path
                            d="M9.1,11.629A.375.375,0,1,0,9.478,12a.374.374,0,0,0-.375-.375h0"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={strokeWidth}
                        ></path>
                        <path
                            d="M15.75,3h4.5a1.5,1.5,0,0,1,1.5,1.5v15a1.5,1.5,0,0,1-1.5,1.5h-4.5"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={strokeWidth}
                        ></path>
                        <path
                            d="M11.894,23.246l-9-1.286a.749.749,0,0,1-.644-.742V3.725a.75.75,0,0,1,.593-.734l9-2.221a.75.75,0,0,1,.907.734v21A.75.75,0,0,1,11.894,23.246Z"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={strokeWidth}
                        ></path>
                    </g>
                </StandardSVG>
            );
        default:
            return (
                <StandardSVG size={size} className={className}>
                    <g transform="matrix(1,0,0,1,0,0)">
                        <path
                            d="M20.25,1.5h-7.5V.5a.5.5,0,0,0-.608-.488l-9,2A.5.5,0,0,0,2.75,2.5v19a.5.5,0,0,0,.392.488l9,2a.5.5,0,0,0,.608-.488v-1h7.5a1,1,0,0,0,1-1V2.5A1,1,0,0,0,20.25,1.5Zm-10,11A1.5,1.5,0,1,1,8.75,11,1.5,1.5,0,0,1,10.25,12.5Zm8.5,8h-5.5a.5.5,0,0,1-.5-.5V4a.5.5,0,0,1,.5-.5h5.5a.5.5,0,0,1,.5.5V20A.5.5,0,0,1,18.75,20.5Z"
                            fill={getColorOrDefault(fill)}
                            stroke="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="0"
                        ></path>
                    </g>
                </StandardSVG>
            );
    }
};

export const LockerRoomWashHands: React.FC<
    React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Bold'>}>
> = ({fill, size, className}) => {
    return (
        <StandardSVG size={size} className={className}>
            <g transform="matrix(1,0,0,1,0,0)">
                <path
                    d="M20.75,17.876H14.5a.5.5,0,0,1,0-1h5.25a1.25,1.25,0,0,0,0-2.5H12a.5.5,0,0,1,0-1h3.5a.5.5,0,0,0,.5-.5v-.5a1.5,1.5,0,0,0-1.5-1.5H9a4.984,4.984,0,0,0-4.95,4.5H2.5a.5.5,0,0,0-.5.5v5.5a.5.5,0,0,0,.5.5H4.555a3.984,3.984,0,0,0,3.445,2H18.75a1.25,1.25,0,0,0,0-2.5H14.5a.5.5,0,0,1,0-1h6.25a1.25,1.25,0,0,0,0-2.5Z"
                    fill={getColorOrDefault(fill)}
                    stroke="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0"
                ></path>
                <path
                    d="M12.250 1.376 A1.250 1.250 0 1 0 14.750 1.376 A1.250 1.250 0 1 0 12.250 1.376 Z"
                    fill={getColorOrDefault(fill)}
                    stroke="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0"
                ></path>
                <path
                    d="M12.250 4.876 A1.250 1.250 0 1 0 14.750 4.876 A1.250 1.250 0 1 0 12.250 4.876 Z"
                    fill={getColorOrDefault(fill)}
                    stroke="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0"
                ></path>
                <path
                    d="M12.250 8.376 A1.250 1.250 0 1 0 14.750 8.376 A1.250 1.250 0 1 0 12.250 8.376 Z"
                    fill={getColorOrDefault(fill)}
                    stroke="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0"
                ></path>
                <path
                    d="M10.069,2.488A1.25,1.25,0,1,0,8.311.991a1.25,1.25,0,0,0,1.758,1.5Z"
                    fill={getColorOrDefault(fill)}
                    stroke="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0"
                ></path>
                <path
                    d="M8,6.126a1.251,1.251,0,1,0-1.114-.681A1.251,1.251,0,0,0,8,6.126Z"
                    fill={getColorOrDefault(fill)}
                    stroke="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0"
                ></path>
                <path
                    d="M6.5,9.626a1.25,1.25,0,1,0-1.112-.681A1.251,1.251,0,0,0,6.5,9.626Z"
                    fill={getColorOrDefault(fill)}
                    stroke="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0"
                ></path>
                <path
                    d="M17.5,2.626A1.252,1.252,0,0,0,18.69.992h0A1.25,1.25,0,1,0,17.5,2.626Z"
                    fill={getColorOrDefault(fill)}
                    stroke="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0"
                ></path>
                <path
                    d="M17.811,5.259a1.249,1.249,0,1,0,.8-1.574A1.252,1.252,0,0,0,17.811,5.259Z"
                    fill={getColorOrDefault(fill)}
                    stroke="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0"
                ></path>
                <path
                    d="M19.388,7.806a1.249,1.249,0,1,0,.727-.62A1.251,1.251,0,0,0,19.388,7.806Z"
                    fill={getColorOrDefault(fill)}
                    stroke="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0"
                ></path>
            </g>
        </StandardSVG>
    );
};

export const MessagesBubble: React.FC<
    React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Regular' | 'Bold'>}>
> = ({fill, size, className, weight}) => {
    switch (weight) {
        case 'Bold':
            return (
                <StandardSVG size={size} className={className}>
                    <g transform="matrix(1,0,0,1,0,0)">
                        <path
                            d="M12,1.337c-6.341,0-11.5,4.25-11.5,9.474c0.04,2.542,1.202,4.936,3.175,6.54l-2.3,4.587 c-0.124,0.247-0.024,0.547,0.223,0.671c0.137,0.069,0.298,0.071,0.436,0.006l6.149-2.862c1.241,0.356,2.526,0.536,3.817,0.533 c6.341,0,11.5-4.25,11.5-9.475S18.341,1.337,12,1.337z"
                            stroke="none"
                            fill={getColorOrDefault(fill)}
                            strokeWidth="0"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                    </g>
                </StandardSVG>
            );
        case 'Regular':
        default:
            return (
                <StandardSVG size={size} className={className}>
                    <g transform="matrix(1,0,0,1,0,0)">
                        <path
                            d="M12,1.337c-6.341,0-11.5,4.25-11.5,9.474c0.04,2.542,1.202,4.936,3.175,6.54l-2.3,4.587 c-0.124,0.247-0.024,0.547,0.223,0.671c0.137,0.069,0.298,0.071,0.436,0.006l6.149-2.862c1.241,0.356,2.526,0.536,3.817,0.533 c6.341,0,11.5-4.25,11.5-9.475S18.341,1.337,12,1.337z"
                            stroke={getColorOrDefault(fill)}
                            fill="none"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                    </g>
                </StandardSVG>
            );
    }
};

export const ModuleFour: React.FC<React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Bold'>}>> = ({
    fill,
    size,
    className,
}) => {
    return (
        <StandardSVG size={size} className={className}>
            <g transform="matrix(1,0,0,1,0,0)">
                <path
                    d="M12,7.772c1.657,0,3,0.672,3,1.5s-1.343,1.5-3,1.5s-3-0.672-3-1.5S10.343,7.772,12,7.772z M12,2.272c1.657,0,3,0.672,3,1.5 s-1.343,1.5-3,1.5S9,4.6,9,3.772S10.343,2.272,12,2.272z M18.5,4.772c1.657,0,3,0.672,3,1.5s-1.343,1.5-3,1.5s-3-0.672-3-1.5 S16.843,4.772,18.5,4.772z M6,4.772c1.657,0,3,0.672,3,1.5s-1.343,1.5-3,1.5S3,7.1,3,6.272S4.343,4.772,6,4.772z M0.9,17.492 l9.649,4.2c0.253,0.11,0.548-0.005,0.658-0.258c0.027-0.063,0.042-0.131,0.042-0.2v-8c0-0.199-0.118-0.379-0.3-0.459L0.7,8.313 c-0.253-0.11-0.548,0.005-0.658,0.258C0.014,8.635,0,8.703,0,8.772v7.344C0,16.713,0.353,17.253,0.9,17.492z M12.75,13.228v8 c0,0.276,0.224,0.5,0.5,0.5c0.069,0,0.137-0.014,0.2-0.042l9.65-4.2c0.547-0.239,0.9-0.778,0.9-1.375V8.772 c0-0.276-0.223-0.5-0.499-0.501c-0.069,0-0.137,0.014-0.201,0.042l-10.25,4.456C12.868,12.849,12.75,13.029,12.75,13.228z"
                    stroke="none"
                    fill={getColorOrDefault(fill)}
                    strokeWidth="0"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
            </g>
        </StandardSVG>
    );
};

export const NavigationMenu: React.FC<
    React.PropsWithChildren<IconProps & {weight: Extract<IconWeight, 'Regular' | 'Bold'>}>
> = ({fill, size, className, weight}) => {
    switch (weight) {
        case 'Bold':
            return (
                <StandardSVG size={size} className={className}>
                    <g transform="matrix(1,0,0,1,0,0)">
                        <path
                            d="M1.5,2.5h21c0.552,0,1,0.448,1,1v1c0,0.552-0.448,1-1,1h-21c-0.552,0-1-0.448-1-1v-1C0.5,2.948,0.948,2.5,1.5,2.5z M1.5,10.5h21c0.552,0,1,0.448,1,1v1c0,0.552-0.448,1-1,1h-21c-0.552,0-1-0.448-1-1v-1C0.5,10.948,0.948,10.5,1.5,10.5z M1.5,18.5 h21c0.552,0,1,0.448,1,1v1c0,0.552-0.448,1-1,1h-21c-0.552,0-1-0.448-1-1v-1C0.5,18.948,0.948,18.5,1.5,18.5z"
                            stroke="none"
                            fill={getColorOrDefault(fill)}
                            strokeWidth="0"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                    </g>
                </StandardSVG>
            );
        case 'Regular':
        default:
            return (
                <StandardSVG size={size} className={className}>
                    <g transform="matrix(1,0,0,1,0,0)">
                        <path
                            d="M 2.25,18.003h19.5 "
                            stroke={getColorOrDefault(fill)}
                            fill="none"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M 2.25,12.003h19.5 "
                            stroke={getColorOrDefault(fill)}
                            fill="none"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M 2.25,6.003h19.5"
                            stroke={getColorOrDefault(fill)}
                            fill="none"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                    </g>
                </StandardSVG>
            );
    }
};

export const OfficeFolder: React.FC<React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Regular'>}>> = ({
    fill,
    size,
    className,
}) => {
    return (
        <StandardSVG size={size} className={className}>
            <g transform="matrix(1,0,0,1,0,0)">
                <path
                    d="M23.23,12.44a1,1,0,0,0-.21-.83,1,1,0,0,0-.77-.37H10.42a1,1,0,0,1-1-.8L9.17,9.05a1,1,0,0,0-1-.8H1.75a1,1,0,0,0-1,1.19l2.59,13a1,1,0,0,0,1,.8H20.26a1,1,0,0,0,1-.8C21.65,20.39,22.74,14.87,23.23,12.44Z"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5px"
                ></path>
                <path
                    d="M4.5,5.25l0-3.5a1,1,0,0,1,1-1h13a1,1,0,0,1,1,1l0,6.5"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5px"
                ></path>
                <line
                    x1="8.26"
                    y1="4.74"
                    x2="15.75"
                    y2="4.75"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5px"
                ></line>
            </g>
        </StandardSVG>
    );
};

export const Pencil: React.FunctionComponent<
    React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Regular' | 'Bold'>}>
> = ({fill, size, className, weight}) => {
    switch (weight) {
        case 'Bold':
            return (
                <StandardSVG size={size} className={className}>
                    <g transform="matrix(1,0,0,1,0,0)">
                        <path
                            d="M16.187,3.511c-0.198-0.188-0.509-0.188-0.707,0L4.067,14.924c-0.195,0.195-0.195,0.512,0,0.707l4.28,4.28 c0.195,0.195,0.512,0.195,0.707,0L20.467,8.5c0.195-0.195,0.195-0.512,0-0.707L16.187,3.511z M3,16.71 c-0.196-0.195-0.512-0.194-0.707,0.002c-0.055,0.056-0.097,0.123-0.121,0.198l-2.14,6.42c-0.06,0.18-0.014,0.378,0.12,0.512 c0.135,0.132,0.332,0.179,0.512,0.121l6.42-2.139c0.263-0.085,0.407-0.367,0.322-0.63c-0.024-0.075-0.066-0.144-0.122-0.199 L3,16.71z M22.968,1.017c-1.396-1.332-3.592-1.332-4.988,0L17.268,1.73c-0.195,0.195-0.195,0.512,0,0.707l4.279,4.28 c0.195,0.195,0.512,0.195,0.707,0L22.968,6C24.34,4.623,24.34,2.394,22.968,1.017z"
                            stroke="none"
                            fill={getColorOrDefault(fill)}
                            strokeWidth="0"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                    </g>
                </StandardSVG>
            );
        case 'Regular':
        default:
            return (
                <StandardSVG size={size} className={className}>
                    <g transform="matrix(1,0,0,1,0,0)">
                        <path
                            d="M 22.192,1.814c-1.421-1.414-3.721-1.409-5.135,0.013c-0.007,0.007-0.015,0.015-0.022,0.022L2.57,16.31 l-1.82,6.94l6.942-1.819L22.157,6.97c1.433-1.4,1.46-3.697,0.06-5.13C22.209,1.831,22.2,1.822,22.192,1.814z "
                            stroke={getColorOrDefault(fill)}
                            fill="none"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M 16.621,2.263 l5.122,5.12 "
                            stroke={getColorOrDefault(fill)}
                            fill="none"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M 14.532,4.351l5.122,5.12 "
                            stroke={getColorOrDefault(fill)}
                            fill="none"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M 2.57,16.31l3.59,1.532l1.536,3.584 "
                            stroke={getColorOrDefault(fill)}
                            fill="none"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M 17.093,6.911L6.16,17.841"
                            stroke={getColorOrDefault(fill)}
                            fill="none"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                    </g>
                </StandardSVG>
            );
    }
};

export const Pencil1: React.FC<React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Bold'>}>> = ({
    fill,
    size,
    className,
}) => {
    return (
        <StandardSVG size={size} className={className}>
            <g transform="matrix(1,0,0,1,0,0)">
                <path
                    d="M15.067,3.986a.5.5,0,0,0-.354-.148h0a.5.5,0,0,0-.354.147L3.437,14.91a.5.5,0,0,0,0,.707l4.948,4.948a.5.5,0,0,0,.707,0L20.009,9.648a.5.5,0,0,0,0-.706Z"
                    fill={getColorOrDefault(fill)}
                    stroke="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0"
                ></path>
                <path
                    d="M2.43,16.8a.5.5,0,0,0-.489-.127.5.5,0,0,0-.351.364L.084,23.314a.5.5,0,0,0,.133.47.507.507,0,0,0,.47.132l6.272-1.5a.5.5,0,0,0,.237-.84Z"
                    fill={getColorOrDefault(fill)}
                    stroke="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0"
                ></path>
                <path
                    d="M23.2,2.924,21.077.8a2.5,2.5,0,0,0-3.532,0L16.127,2.217a.5.5,0,0,0,0,.707l4.95,4.949a.5.5,0,0,0,.707,0L23.2,6.454A2.5,2.5,0,0,0,23.2,2.924Z"
                    fill={getColorOrDefault(fill)}
                    stroke="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0"
                ></path>
            </g>
        </StandardSVG>
    );
};

export const PencilWrite1Alternate: React.FC<
    React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Bold'>}>
> = ({fill, size, className}) => {
    return (
        <StandardSVG size={size} className={className}>
            <g transform="matrix(1,0,0,1,0,0)">
                <path
                    d="M20,11.488a1,1,0,0,0-1,1v8.5a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1v-17a1,1,0,0,1,1-1H13a1,1,0,0,0,0-2H3a3,3,0,0,0-3,3v17a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3v-8.5A1,1,0,0,0,20,11.488Z"
                    fill={getColorOrDefault(fill)}
                    stroke="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0"
                ></path>
                <path
                    d="M23.415.578a2.048,2.048,0,0,0-2.828,0L19.526,1.639a.5.5,0,0,0,0,.707l2.121,2.121a.5.5,0,0,0,.707,0l1.061-1.061A2,2,0,0,0,23.415.578Z"
                    fill={getColorOrDefault(fill)}
                    stroke="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0"
                ></path>
                <path
                    d="M11.359,10.514l2.121,2.121a.5.5,0,0,0,.707,0l6.364-6.364a.5.5,0,0,0,0-.708L18.429,3.441a.516.516,0,0,0-.707,0L11.359,9.807A.5.5,0,0,0,11.359,10.514Z"
                    fill={getColorOrDefault(fill)}
                    stroke="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0"
                ></path>
                <path
                    d="M10.349,11.235a.506.506,0,0,0-.364.3L8.773,14.57a.5.5,0,0,0,.111.539.529.529,0,0,0,.539.111l3.03-1.212a.5.5,0,0,0,.168-.818L10.8,11.372A.5.5,0,0,0,10.349,11.235Z"
                    fill={getColorOrDefault(fill)}
                    stroke="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0"
                ></path>
            </g>
        </StandardSVG>
    );
};

export const Pill: React.FC<React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Bold'>}>> = ({
    fill,
    size,
    className,
}) => {
    return (
        <StandardSVG size={size} className={className}>
            <g transform="matrix(1,0,0,1,0,0)">
                <path
                    d="M21.89,2.113a7.218,7.218,0,0,0-10.2,0L2.12,11.687A7.2,7.2,0,0,0,7.21,24h0a7.156,7.156,0,0,0,5.095-2.105l9.581-9.581a7.231,7.231,0,0,0,0-10.2ZM20.476,10.9,16.75,14.622a.5.5,0,0,1-.707,0L9.382,7.961a.5.5,0,0,1,0-.707l3.726-3.727a5.2,5.2,0,0,1,8.885,3.692A5.159,5.159,0,0,1,20.476,10.9Z"
                    fill={getColorOrDefault(fill)}
                    stroke="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0"
                ></path>
            </g>
        </StandardSVG>
    );
};

export const QuestionCircle: React.FC<React.PropsWithChildren<IconProps & {strokeWidth?: number}>> = ({
    fill,
    size,
    className,
    strokeWidth = 1.5,
}) => {
    return (
        <StandardSVG size={size} className={className}>
            <g transform="matrix(1,0,0,1,0,0)">
                <path
                    d="M9,9a3,3,0,1,1,4,2.829,1.5,1.5,0,0,0-1,1.415V14.25"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={strokeWidth}
                ></path>
                <path
                    d="M12,17.25a.375.375,0,1,0,.375.375A.375.375,0,0,0,12,17.25h0"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={strokeWidth}
                ></path>
                <path
                    d="M0.750 12.000 A11.250 11.250 0 1 0 23.250 12.000 A11.250 11.250 0 1 0 0.750 12.000 Z"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeWidth={strokeWidth}
                ></path>
            </g>
        </StandardSVG>
    );
};

export const RemoveCircleBold: React.FC<
    React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Bold'>}>
> = ({fill, size, className}) => {
    return (
        <StandardSVG size={size} className={className}>
            <g transform="matrix(1,0,0,1,0,0)">
                <path
                    d="M12,23.5A11.5,11.5,0,1,0,.5,12,11.513,11.513,0,0,0,12,23.5ZM7.05,9.172a1,1,0,0,1,0-1.415l.707-.707a1,1,0,0,1,1.415,0L11.823,9.7a.25.25,0,0,0,.354,0L14.828,7.05a1,1,0,0,1,1.415,0l.707.707a1,1,0,0,1,0,1.415L14.3,11.823a.25.25,0,0,0,0,.354l2.652,2.651a1,1,0,0,1,0,1.415l-.707.707a1,1,0,0,1-1.415,0L12.177,14.3a.252.252,0,0,0-.354,0L9.172,16.95a1,1,0,0,1-1.415,0l-.707-.707a1,1,0,0,1,0-1.415L9.7,12.177a.25.25,0,0,0,0-.354Z"
                    fill={getColorOrDefault(fill)}
                    stroke="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0"
                ></path>
            </g>
        </StandardSVG>
    );
};

export const RSSFeedSquare: React.FC<
    React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Light' | 'Bold'>}>
> = ({fill, size, className, weight}) => {
    if (weight === 'Bold') {
        return (
            <StandardSVG size={size} className={className}>
                <g transform="matrix(1,0,0,1,0,0)">
                    <path
                        d="M20,0H4A4,4,0,0,0,0,4V20a4,4,0,0,0,4,4H20a4,4,0,0,0,4-4V4A4,4,0,0,0,20,0ZM5.5,11a1,1,0,0,1,0-2A9.511,9.511,0,0,1,15,18.5a1,1,0,0,1-2,0A7.509,7.509,0,0,0,5.5,11Zm4,6A2.5,2.5,0,1,1,7,14.5,2.5,2.5,0,0,1,9.5,17ZM5.5,6a1,1,0,0,1,0-2A14.517,14.517,0,0,1,20,18.5a1,1,0,0,1-2,0A12.514,12.514,0,0,0,5.5,6Z"
                        fill={getColorOrDefault(fill)}
                        stroke="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="0"
                    ></path>
                </g>
            </StandardSVG>
        );
    }

    return (
        <StandardSVG size={size} className={className}>
            <g transform="matrix(1,0,0,1,0,0)">
                <path
                    d="M5,10.5A8.5,8.5,0,0,1,13.5,19"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M5,5.5A13.5,13.5,0,0,1,18.5,19"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M5.500 16.998 A1.500 1.500 0 1 0 8.500 16.998 A1.500 1.500 0 1 0 5.500 16.998 Z"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M23.5,20.5a3.009,3.009,0,0,1-3,3H3.5a3.009,3.009,0,0,1-3-3V3.5a3.009,3.009,0,0,1,3-3h17a3.009,3.009,0,0,1,3,3Z"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
            </g>
        </StandardSVG>
    );
};

export const RSSFeed2: React.FC<
    React.PropsWithChildren<IconProps & {weight: Extract<IconWeight, 'Regular' | 'Bold'>; strokeWidth?: number}>
> = ({fill, size, className, weight, strokeWidth = 1.5}) => {
    if (weight === 'Regular') {
        return (
            <StandardSVG size={size} className={className}>
                <circle
                    cx="4.5"
                    cy="19.497"
                    r="3.75"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={strokeWidth}
                ></circle>
                <path
                    d="M.75,8.844a11.328,11.328,0,0,1,14.4,14.4"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={strokeWidth}
                ></path>
                <path
                    d="M.75,1.113A18.777,18.777,0,0,1,22.889,23.236"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={strokeWidth}
                ></path>
            </StandardSVG>
        );
    } else {
        return (
            <StandardSVG size={size} className={className}>
                <g>
                    <path
                        d="M1.5,8.27a1.5,1.5,0,0,0,0,3,11.11,11.11,0,0,1,11.44,11,1.5,1.5,0,0,0,3,0C15.94,14.41,9.6,8.27,1.5,8.27Z"
                        fill={getColorOrDefault(fill)}
                    ></path>
                    <path
                        d="M1.53.24a1.5,1.5,0,0,0,0,3c10.92,0,19.47,8.35,19.47,19a1.5,1.5,0,1,0,3,0C24,9.91,14.13.24,1.53.24Z"
                        fill={getColorOrDefault(fill)}
                    ></path>
                    <circle cx="3.5" cy="20.25" r="3.5" fill={getColorOrDefault(fill)}></circle>
                </g>
            </StandardSVG>
        );
    }
};

export const SavingBank1: React.FC<React.PropsWithChildren<IconProps & {weight: Extract<IconWeight, 'Regular'>}>> = ({
    fill,
    size,
    className,
}) => {
    return (
        <StandardSVG size={size} className={className}>
            <g transform="matrix(1,0,0,1,0,0)">
                <path
                    d="M 1.5,23.25h21 "
                    stroke={getColorOrDefault(fill)}
                    fill="none"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M 1.5,20.25h21 "
                    stroke={getColorOrDefault(fill)}
                    fill="none"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M 3,9.75v7.5 "
                    stroke={getColorOrDefault(fill)}
                    fill="none"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M 6,9.75v7.5 "
                    stroke={getColorOrDefault(fill)}
                    fill="none"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M 10.5,9.75v7.5 "
                    stroke={getColorOrDefault(fill)}
                    fill="none"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M 13.5,9.75v7.5 "
                    stroke={getColorOrDefault(fill)}
                    fill="none"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M 18,9.75v7.5 "
                    stroke={getColorOrDefault(fill)}
                    fill="none"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M 21,9.75 v7.5 "
                    stroke={getColorOrDefault(fill)}
                    fill="none"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M 22.5,6.75h-21l9.689-5.762c0.494-0.318,1.128-0.318,1.622,0L22.5,6.75z"
                    stroke={getColorOrDefault(fill)}
                    fill="none"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
            </g>
        </StandardSVG>
    );
};

export const Search: React.FC<
    React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Regular' | 'Bold'>}>
> = ({fill, size, className, weight}) => {
    switch (weight) {
        case 'Regular':
            return (
                <StandardSVG className={className} size={size}>
                    <g transform="matrix(1,0,0,1,0,0)">
                        <path
                            d="M0.750 9.812 A9.063 9.063 0 1 0 18.876 9.812 A9.063 9.063 0 1 0 0.750 9.812 Z"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            transform="translate(-3.056 4.62) rotate(-23.025)"
                        ></path>
                        <path
                            d="M16.221 16.22L23.25 23.25"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                        ></path>
                    </g>
                </StandardSVG>
            );
        case 'Bold':
        default:
            return (
                <StandardSVG className={className} size={size}>
                    <g transform="matrix(1,0,0,1,0,0)">
                        <path
                            d="M23.414,20.591l-4.645-4.645c3.146-4.71,1.879-11.079-2.831-14.225S4.859-0.158,1.713,4.552s-1.879,11.079,2.831,14.225 c3.45,2.305,7.948,2.304,11.397-0.002l4.645,4.644c0.787,0.767,2.041,0.767,2.828,0c0.793-0.768,0.836-2.012,0.067-2.805 C23.466,20.598,23.451,20.583,23.414,20.591z M10.25,3.005c4.004,0,7.25,3.246,7.25,7.25s-3.246,7.25-7.25,7.25S3,14.259,3,10.255 C3.004,6.253,6.248,3.009,10.25,3.005z"
                            fill={getColorOrDefault(fill)}
                            stroke="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="0"
                        ></path>
                    </g>
                </StandardSVG>
            );
    }
};

export const SingleNeutral: React.FC<React.PropsWithChildren<IconProps>> = ({fill, size, className}) => {
    return (
        <StandardSVG size={size} className={className}>
            <g transform="matrix(1,0,0,1,0,0)">
                <path
                    d="M6.750 6.000 A5.250 5.250 0 1 0 17.250 6.000 A5.250 5.250 0 1 0 6.750 6.000 Z"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                ></path>
                <path
                    d="M2.25,23.25a9.75,9.75,0,0,1,19.5,0"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                ></path>
            </g>
        </StandardSVG>
    );
};

// Person - neutral
export const SingleNeutralActions: React.FC<
    React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Bold'>}>
> = ({fill, size, className}) => {
    return (
        <StandardSVG size={size} className={className}>
            <g transform="matrix(1,0,0,1,0,0)">
                <path
                    d="M19.752,16.454c-0.539-1.076-2.307-1.725-4.754-2.626l-0.628-0.23c-0.075-0.088-0.123-0.196-0.138-0.311 c-0.092-0.431-0.072-0.879,0.058-1.3c1.076-1.166,1.609-2.733,1.466-4.314C15.756,5.256,14.177,3.5,12,3.5S8.244,5.256,8.244,7.674 c-0.144,1.574,0.383,3.135,1.451,4.3c0.144,0.424,0.169,0.879,0.073,1.316c-0.015,0.114-0.062,0.221-0.135,0.309L9,13.828 c-2.447,0.9-4.215,1.55-4.754,2.626C3.764,17.575,3.51,18.78,3.5,20c0,0.276,0.224,0.5,0.5,0.5h16c0.276,0,0.5-0.224,0.5-0.5 C20.489,18.78,20.235,17.574,19.752,16.454z"
                    stroke="none"
                    fill={getColorOrDefault(fill)}
                    strokeWidth="0"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
            </g>
        </StandardSVG>
    );
};

export const SingleNeutralActionsSettings: React.FC<
    React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Regular'>; strokeWidth?: number}>
> = ({fill, size, className, strokeWidth = 1.5}) => (
    <StandardSVG size={size} className={className}>
        <g transform="matrix(1,0,0,1,0,0)">
            <path
                d="M 0.75,17.25c0.003-3.73,3.028-6.751,6.758-6.748c0.908,0.001,1.807,0.184,2.642,0.54 "
                stroke={getColorOrDefault(fill)}
                fill="none"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M 7.5,0.75 c2.278,0,4.125,1.847,4.125,4.125S9.778,9,7.5,9S3.375,7.153,3.375,4.875S5.222,0.75,7.5,0.75z "
                stroke={getColorOrDefault(fill)}
                fill="none"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M 17.25,15 c0.828,0,1.5,0.672,1.5,1.5c0,0.828-0.672,1.5-1.5,1.5s-1.5-0.672-1.5-1.5C15.75,15.672,16.422,15,17.25,15z "
                stroke={getColorOrDefault(fill)}
                fill="none"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M 18.524,10.7 l0.442,1.453c0.151,0.5,0.664,0.798,1.174,0.681l1.472-0.341c0.721-0.163,1.438,0.29,1.601,1.012 c0.097,0.431-0.025,0.882-0.326,1.206l-1.031,1.111c-0.357,0.384-0.357,0.978,0,1.362l1.031,1.111 c0.504,0.542,0.473,1.389-0.069,1.892c-0.324,0.301-0.775,0.423-1.206,0.326l-1.472-0.341c-0.51-0.117-1.023,0.181-1.174,0.681 L18.524,22.3c-0.211,0.704-0.952,1.103-1.656,0.892c-0.428-0.128-0.764-0.464-0.892-0.892l-0.442-1.453 c-0.151-0.5-0.664-0.798-1.174-0.681l-1.472,0.341c-0.721,0.163-1.438-0.29-1.601-1.012c-0.097-0.431,0.025-0.882,0.326-1.206 l1.031-1.111c0.357-0.384,0.357-0.978,0-1.362l-1.031-1.111c-0.504-0.542-0.473-1.389,0.069-1.892 c0.324-0.301,0.775-0.423,1.206-0.326l1.472,0.341c0.51,0.117,1.023-0.181,1.174-0.681l0.442-1.453 c0.213-0.703,0.955-1.101,1.658-0.888C18.062,9.935,18.397,10.271,18.524,10.7z"
                stroke={getColorOrDefault(fill)}
                fill="none"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </g>
    </StandardSVG>
);

export const SingleNeutralProfilePicture: React.FC<
    React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Regular'>}>
> = ({fill, size, className}) => (
    <StandardSVG size={size} className={className}>
        <g transform="matrix(1,0,0,1,0,0)">
            <path
                d="M 3.75,0.75h16.5c0.828,0,1.5,0.672,1.5,1.5v19.5c0,0.828-0.672,1.5-1.5,1.5H3.75c-0.828,0-1.5-0.672-1.5-1.5 V2.25C2.25,1.422,2.922,0.75,3.75,0.75z "
                stroke={getColorOrDefault(fill)}
                fill="none"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M 12,4.973c2.27,0,4.11,1.84,4.11,4.11s-1.84,4.11-4.11,4.11s-4.11-1.84-4.11-4.11 S9.73,4.973,12,4.973z "
                stroke={getColorOrDefault(fill)}
                fill="none"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M 18.75,19.027c-1.965-3.728-6.579-5.157-10.307-3.193c-1.362,0.718-2.475,1.831-3.193,3.193"
                stroke={getColorOrDefault(fill)}
                fill="none"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </g>
    </StandardSVG>
);

export const StudyOwl: React.FC<
    React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Regular' | 'Bold'>; strokeWidth?: number}>
> = ({fill, size, className, weight, strokeWidth = 1.5}) => {
    switch (weight) {
        case 'Bold':
            return (
                <StandardSVG size={size} className={className}>
                    <g transform="matrix(1,0,0,1,0,0)">
                        <path
                            d="M8.003 10.497 A1.000 1.000 0 1 0 10.003 10.497 A1.000 1.000 0 1 0 8.003 10.497 Z"
                            fill={getColorOrDefault(fill)}
                            stroke="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="0"
                        ></path>
                        <path
                            d="M14.003 10.497 A1.000 1.000 0 1 0 16.003 10.497 A1.000 1.000 0 1 0 14.003 10.497 Z"
                            fill={getColorOrDefault(fill)}
                            stroke="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="0"
                        ></path>
                        <path
                            d="M19.007,11.568a.5.5,0,0,1-.293-.529,3.75,3.75,0,0,0-1.285-3.4.5.5,0,0,1-.176-.381V5.85a.25.25,0,0,0-.342-.232L12.905,7.193a2.49,2.49,0,0,1-1.749.03L7.086,5.781a.249.249,0,0,0-.333.235V7.259a.5.5,0,0,1-.176.381A3.743,3.743,0,0,0,5.253,10.5a3.687,3.687,0,0,0,.039.539.5.5,0,0,1-.294.53A3.755,3.755,0,0,0,2.75,15v2.25a5.76,5.76,0,0,0,3.988,5.474.25.25,0,0,0,.284-.378A5.964,5.964,0,0,1,6,19a.5.5,0,0,1,1,0,5,5,0,0,0,10,0,.5.5,0,0,1,1,0,5.964,5.964,0,0,1-1.022,3.346.25.25,0,0,0,.284.378,5.76,5.76,0,0,0,3.988-5.474V15A3.755,3.755,0,0,0,19.007,11.568ZM9,12.747a2.25,2.25,0,1,1,2.25-2.25A2.253,2.253,0,0,1,9,12.747Zm4.172,1.334-.634.929a.677.677,0,0,1-1.076,0l-.633-.928a.25.25,0,0,1,.078-.356,3.758,3.758,0,0,0,.9-.75.25.25,0,0,1,.376,0,3.778,3.778,0,0,0,.9.75.251.251,0,0,1,.079.356ZM15,12.747a2.25,2.25,0,1,1,2.25-2.25A2.253,2.253,0,0,1,15,12.747Z"
                            fill={getColorOrDefault(fill)}
                            stroke="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="0"
                        ></path>
                        <path
                            d="M4.75,7.434a1,1,0,0,0,1-1V4.6a.247.247,0,0,1,.106-.2.25.25,0,0,1,.228-.032L11.489,6.28a1.494,1.494,0,0,0,1.05-.017l6.052-2.381a.75.75,0,0,0,0-1.4L12.53.105A1.5,1.5,0,0,0,11.48.088l-7.4,2.625a.5.5,0,0,0-.333.471v3.25A1,1,0,0,0,4.75,7.434Z"
                            fill={getColorOrDefault(fill)}
                            stroke="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="0"
                        ></path>
                    </g>
                </StandardSVG>
            );
        case 'Regular':
        default:
            return (
                <StandardSVG size={size} className={className}>
                    <g transform="matrix(1,0,0,1,0,0)">
                        <path
                            d="M12.4,5.144,20.25,3,12.4.859a1.512,1.512,0,0,0-.79,0L3.75,3l7.855,2.143A1.512,1.512,0,0,0,12.4,5.144Z"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={strokeWidth}
                        ></path>
                        <path
                            d="M17.25 7.154L17.25 3.82"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={strokeWidth}
                        ></path>
                        <path
                            d="M7.5 4.024L7.5 6.847"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={strokeWidth}
                        ></path>
                        <path
                            d="M3.75 3.001L3.75 6.001"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={strokeWidth}
                        ></path>
                        <path
                            d="M5.250 9.751 A3.000 3.000 0 1 0 11.250 9.751 A3.000 3.000 0 1 0 5.250 9.751 Z"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={strokeWidth}
                        ></path>
                        <path
                            d="M8.25,9.376a.375.375,0,0,0-.375.375"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={strokeWidth}
                        ></path>
                        <path
                            d="M8.625,9.751a.375.375,0,0,0-.375-.375"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={strokeWidth}
                        ></path>
                        <path
                            d="M8.25,10.126a.375.375,0,0,0,.375-.375"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={strokeWidth}
                        ></path>
                        <path
                            d="M7.875,9.751a.375.375,0,0,0,.375.375"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={strokeWidth}
                        ></path>
                        <path
                            d="M12.750 9.751 A3.000 3.000 0 1 0 18.750 9.751 A3.000 3.000 0 1 0 12.750 9.751 Z"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={strokeWidth}
                        ></path>
                        <path
                            d="M15.75,9.376a.375.375,0,0,0-.375.375"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={strokeWidth}
                        ></path>
                        <path
                            d="M16.125,9.751a.375.375,0,0,0-.375-.375"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={strokeWidth}
                        ></path>
                        <path
                            d="M15.75,10.126a.375.375,0,0,0,.375-.375"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={strokeWidth}
                        ></path>
                        <path
                            d="M15.375,9.751a.375.375,0,0,0,.375.375"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={strokeWidth}
                        ></path>
                        <path
                            d="M9.484 12.486L12 15.001 14.516 12.486"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={strokeWidth}
                        ></path>
                        <path
                            d="M18,15v2.337a6,6,0,0,1-12,0V15"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={strokeWidth}
                        ></path>
                        <path
                            d="M18.225,11.447a3.132,3.132,0,0,1,3.525,3.076v2.065a5.217,5.217,0,0,1-5.732,5.142"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={strokeWidth}
                        ></path>
                        <path
                            d="M5.657,11.262A3.149,3.149,0,0,0,2.25,14.4v2.1A5.251,5.251,0,0,0,7.982,21.73"
                            fill="none"
                            stroke={getColorOrDefault(fill)}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={strokeWidth}
                        ></path>
                    </g>
                </StandardSVG>
            );
    }
};

export const TimeClockCircle: React.FC<React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Bold'>}>> = ({
    fill,
    size,
    className,
}) => (
    <StandardSVG size={size} className={className}>
        <g transform="matrix(1,0,0,1,0,0)">
            <path
                d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm0,22A10,10,0,1,1,22,12,10.011,10.011,0,0,1,12,22Z"
                fill={getColorOrDefault(fill)}
                stroke="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0"
            ></path>
            <path
                d="M17.134,15.81,12.5,11.561V6.5a1,1,0,0,0-2,0V12a1,1,0,0,0,.324.738l4.959,4.545a1.01,1.01,0,0,0,1.413-.061A1,1,0,0,0,17.134,15.81Z"
                fill={getColorOrDefault(fill)}
                stroke="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0"
            ></path>
        </g>
    </StandardSVG>
);

export const DiagramRound: React.FC<React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Bold'>}>> = ({
    fill,
    size,
    className,
}) => {
    return (
        <StandardSVG size={size} className={className}>
            <g transform="matrix(1,0,0,1,0,0)">
                <path
                    d="M13.1,1.1a1.44,1.44,0,1,0,0,2.88A8.024,8.024,0,1,1,6.242,16.163a.25.25,0,0,1,.162-.375l.889-.187a1.44,1.44,0,0,0,.489-2.616L4.419,10.793a1.515,1.515,0,0,0-1.078-.2,1.5,1.5,0,0,0-.908.616l-2.2,3.371A1.44,1.44,0,0,0,1.44,16.8a1.506,1.506,0,0,0,.3-.031l1.231-.26a.251.251,0,0,1,.278.137A10.9,10.9,0,1,0,13.1,1.1Z"
                    fill={getColorOrDefault(fill)}
                    stroke="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0"
                ></path>
            </g>
        </StandardSVG>
    );
};

// https://app.streamlinehq.com/icons/streamline-regular/files-folders/folders/folder-file-1
export const FolderFile1: React.FC<
    React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Regular'>; strokeWidth?: number}>
> = ({fill, size, className, strokeWidth = 1.5}) => {
    return (
        <StandardSVG size={size} className={className}>
            <g transform="matrix(1,0,0,1,0,0)">
                <path
                    d="M23.231,12.435a1,1,0,0,0-.979-1.193H10.424a1,1,0,0,1-.978-.8L9.168,9.051a1,1,0,0,0-.978-.8H1.747A1,1,0,0,0,.769,9.444l2.593,13a1,1,0,0,0,.978.8H20.258a1,1,0,0,0,.978-.8C21.647,20.392,22.744,14.869,23.231,12.435Z"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={strokeWidth}
                ></path>
                <path
                    d="M4.5,5.25l.02-3.5a1,1,0,0,1,1-1H18.483a1,1,0,0,1,1,1l.02,6.5"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={strokeWidth}
                ></path>
                <path
                    d="M7.512 3.742L15.75 3.75"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={strokeWidth}
                ></path>
                <path
                    d="M12 6.75L15.75 6.75"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={strokeWidth}
                ></path>
            </g>
        </StandardSVG>
    );
};

export const PencilWrite: React.FC<
    React.PropsWithChildren<IconProps & {weight?: Extract<IconWeight, 'Regular'>; strokeWidth?: number}>
> = ({fill, size, className, strokeWidth = 1.5}) => {
    return (
        <StandardSVG size={size} className={className}>
            <g transform="matrix(1,0,0,1,0,0)">
                <path
                    d="M13.045,14.136l-3.712.531.53-3.713,9.546-9.546A2.25,2.25,0,0,1,22.591,4.59Z"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={strokeWidth}
                ></path>
                <path
                    d="M18.348 2.469L21.53 5.651"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                ></path>
                <path
                    d="M18.75,14.25v7.5a1.5,1.5,0,0,1-1.5,1.5h-15a1.5,1.5,0,0,1-1.5-1.5v-15a1.5,1.5,0,0,1,1.5-1.5h7.5"
                    fill="none"
                    stroke={getColorOrDefault(fill)}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                ></path>
            </g>
        </StandardSVG>
    );
};
