import {type Epic} from 'redux-observable';
import {type AnyAction, type Action as ReduxAction} from 'redux';
import type Redux from 'redux';
import {type Observable} from 'rxjs';
import {type Record} from 'immutable';

import {type Action} from 'web-app/util/redux/action-types';

import {RequestMethod, type RequestType} from './constants';
import * as ActionsFactory from './actions-factory';
import * as EpicsFactory from './epics-factory';
import * as ReducerFactory from './reducer-factory';
import * as SelectorsFactory from './selectors-factory';
import {type ActionsBundle} from './actions-factory';
import {type EntityState} from './reducer-factory';

export type RF<IRecord extends {}> = Record.Factory<IRecord>;

export interface EntityModel<IRecord extends {}, TRecord extends RF<IRecord> = RF<IRecord>> {
    record: TRecord;
    fromAPI: (data: any, originalPayload?: any) => InstanceType<TRecord>;
    toAPI?: (data: InstanceType<TRecord>) => any; // 'any' should ideally be a type from the rest api
    default?: InstanceType<TRecord>;
    records?: {[name: string]: any};
}

type ResponseHandlers = {[K in RequestType]?: (response: any, originalPayload: any) => any};

export interface APIRequestTypeDescription {
    url: ((payload: any) => string) | string;
    requestPayload?: (payload: any) => any;
    requestMethod?: ((payload: any) => RequestMethod | undefined) | RequestMethod;
    locateResponse?: (response: any) => any;
}

export type APIDescription = {[K in RequestType]?: APIRequestTypeDescription};

export interface EntityRelation<RootState, IRecord extends {}, TRecord extends RF<IRecord>> {
    entity: Entity<RootState, IRecord, TRecord>;
    responseHandlers: ResponseHandlers;
}

export type EntityRelations = Array<EntityRelation<any, any, any>>;

export type ReducerExtension<S = any, A extends ReduxAction = AnyAction> = (state: S, action: A) => S;

export interface EntityConfiguration<IRecord extends {}, TRecord extends RF<IRecord> = RF<IRecord>> {
    domain: string;
    model: EntityModel<IRecord, TRecord>;
    reducerExtension?: ReducerExtension<EntityState<IRecord>>;
    apiDescription: APIDescription;
    entityName: string;
    entityRelations?: EntityRelations;
    groupByKeys?: string[];
    entityIdentifier?: string;
}

export interface Entity<RootState, IRecord extends {}, TRecord extends RF<IRecord> = RF<IRecord>, Selectors = any> {
    domain: string;
    model: EntityModel<IRecord, TRecord>;
    reducer: Redux.Reducer<EntityState<IRecord>>;
    epics: Epic<Action<any>, Action<any>, RootState, any>;
    observables: {[K in RequestType]?: (payload: any) => Observable<Action<any>>};
    actions: ActionsBundle;
    selectors: Selectors;
}

const makeCreateEntity =
    (
        dispatch: (arg: any) => void,
        isDevelopment: boolean,
        onEntityInstanceCreated: (entityName: string) => Action<any>,
        onEntityInstanceDeleted: (entityName: string) => Action<any>,
        RESTClient,
        handleDeleteConfirm: (message: string) => Observable<0 | 1>,
    ) =>
    <IRecord extends {}, TRecord extends RF<IRecord>, RootState>(
        configuration: EntityConfiguration<IRecord, TRecord>,
    ) => {
        const {
            domain,
            model,
            reducerExtension,
            apiDescription = {},
            entityName,
            entityRelations = [],
            groupByKeys,
            entityIdentifier,
        } = configuration;

        const actions = ActionsFactory.createActions(domain, apiDescription, dispatch, isDevelopment);
        const reducer = ReducerFactory.createReducer<IRecord>(actions, reducerExtension, groupByKeys, entityIdentifier);
        const {epics, observables} = EpicsFactory.createEpics<IRecord, TRecord, RootState>(
            apiDescription,
            actions,
            model,
            entityName,
            entityRelations,
            onEntityInstanceCreated,
            onEntityInstanceDeleted,
            RESTClient,
            handleDeleteConfirm,
        );

        const reduxKeyPath = ReducerFactory.getReduxKeyPath(domain);

        const selectors = SelectorsFactory.createSelectors<IRecord, RootState>(
            reduxKeyPath,
            apiDescription,
            entityRelations,
            groupByKeys,
        );

        return {
            domain,
            model,
            actions,
            reducer,
            epics,
            observables,
            selectors,
        };
    };

/*
 * Creates EntityFactory that can create entities with
 * reducers, epics, actions and selectors for
 * a specific app (Famly App, Sign-In App)
 */
export const entityFactoryCreator = (
    dispatch: (arg: any) => void,
    isDevelopment: boolean,
    onEntityInstanceCreated: (entityName: string) => Action<any>,
    onEntityInstanceDeleted: (entityName: string) => Action<any>,
    RESTClient,
    handleDeleteConfirm: (message: string) => Observable<0 | 1>,
) => ({
    createEntity: makeCreateEntity(
        dispatch,
        isDevelopment,
        onEntityInstanceCreated,
        onEntityInstanceDeleted,
        RESTClient,
        handleDeleteConfirm,
    ),
    RequestMethod,
});
