import {type Epic, ofType} from 'redux-observable';
import {List} from 'immutable';
import {type Observable, of} from 'rxjs';
import i18next from 'i18next';
import {mergeMap, flatMap, catchError, map} from 'rxjs/operators';

import {combineEpics} from 'web-app/util/redux-observable';
import {
    type APIDescription,
    type APIRequestTypeDescription,
    type EntityModel,
    type EntityRelations,
    type RF,
} from 'web-app/react/entities/factory/index';
import {type Action} from 'web-app/util/redux/action-types';
import {type ActionsBundle, type EventMap} from 'web-app/react/entities/factory/actions-factory';

import {InternalAction, RequestMethod, type RequestType} from './constants';

// To support APIs where other request methods than the expected ones are used.
// e.g. a delete api uses POST
const getRestFunction = (method: RequestMethod, RESTClient) => {
    switch (method) {
        case RequestMethod.DELETE:
            return RESTClient.deleteAuthenticatedObservable;
        case RequestMethod.POST:
            return RESTClient.postAuthenticatedObservable;
        case RequestMethod.GET:
            return RESTClient.getAuthenticatedObservable;
        default:
            throw new Error(`Uknown type ${method} given to getRestFunction in epics factory`);
    }
};

type GetRequestMethod = (
    endpointDescription: any,
    defaultMethod: RequestMethod,
    payload: any,
    RESTClient: any,
) => (...args: any[]) => Observable<any>;

const getRequestMethodFromEndpointDescription: GetRequestMethod = (
    endpointDescription: any,
    defaultMethod: RequestMethod,
    payload: any,
    RESTClient,
) => {
    const {requestMethod} = endpointDescription;

    const methodFromDescription = typeof requestMethod === 'function' ? requestMethod(payload) : requestMethod;

    return getRestFunction(methodFromDescription || defaultMethod, RESTClient);
};

const getUrl = (requestTypeDescription: APIRequestTypeDescription, payload: any) => {
    const url = requestTypeDescription.url;

    if (typeof url === 'function') {
        return url(payload);
    } else if (typeof url === 'string') {
        return url;
    } else {
        throw Error('url must be a function or a string');
    }
};

const getRequestPayload = (requestTypeDescription: APIRequestTypeDescription, payload: any) => {
    const requestPayload = requestTypeDescription.requestPayload;

    if (typeof requestPayload === 'function') {
        return requestPayload(payload);
    } else if (!requestPayload) {
        return payload;
    } else {
        throw Error('requestPayload must be a function or empty');
    }
};

const locateResponse = (requestTypeDescription: APIRequestTypeDescription, response: any) => {
    if (typeof requestTypeDescription.locateResponse === 'function') {
        return requestTypeDescription.locateResponse(response);
    } else {
        return response;
    }
};

const getEntityReferences = (
    requestKey: RequestType,
    response: any,
    entityRelations: EntityRelations,
    originalPayload: any,
) => {
    return entityRelations
        .filter(relation => typeof relation.responseHandlers[requestKey] === 'function')
        .map(relation => {
            const responseItems = relation.responseHandlers[requestKey]!(response, originalPayload);
            const items = List(responseItems).map(relation.entity.model.fromAPI);
            return relation.entity.actions.internalActions[InternalAction.save].action(items);
        });
};

const epicFromObservable = <RootState>(
    observable: (payload: any) => Observable<Array<Action<any>>>,
    actionCollection: EventMap,
): Epic<Action<any>, Action<any>, RootState> => {
    return action$ =>
        action$.pipe(
            ofType(actionCollection.start.type),
            mergeMap(({payload}) => {
                return observable(payload).pipe(
                    flatMap(a => a),
                    catchError((e: any) => of(actionCollection.failed.action(e, payload))),
                );
            }),
        );
};

export const createEpics = <IRecord extends {}, TRecord extends RF<IRecord>, RootState>(
    apiDescription: APIDescription,
    actions: ActionsBundle,
    entityModel: EntityModel<IRecord, TRecord>,
    entityName: string,
    entityRelations: EntityRelations,
    onEntityInstanceCreated: (entityName: string) => Action<any>,
    onEntityInstanceDeleted: (entityName: string) => Action<any>,
    RESTClient,
    handleDeleteConfirm: (message: string) => Observable<0 | 1>,
) => {
    const observablesObject: {[K in RequestType]?: (payload: any) => Observable<Array<Action<any>>>} = {};
    const epicsArray: Epic[] = [];

    if (apiDescription.create) {
        const endpointDescription = apiDescription.create;

        const createObservable = (payload: any) => {
            const url = getUrl(endpointDescription, payload);
            const requestPayload = getRequestPayload(endpointDescription, payload);

            return RESTClient.postAuthenticatedObservable(url, requestPayload)
                .map((response: any) =>
                    entityModel.fromAPI(locateResponse(endpointDescription, response), requestPayload),
                )
                .map(response => {
                    return [actions.create!.success.action(response, payload), onEntityInstanceCreated(entityName)];
                });
        };

        const createEpic = epicFromObservable<RootState>(createObservable, actions.create!);

        observablesObject.create = createObservable;
        epicsArray.push(createEpic);
    }

    if (apiDescription.fetch) {
        const endpointDescription = apiDescription.fetch;

        const readObservable = (payload: any) => {
            const url = getUrl(endpointDescription, payload);
            const requestPayload = getRequestPayload(endpointDescription, payload);

            return RESTClient.getAuthenticatedObservable(url, requestPayload)
                .map(response => entityModel.fromAPI(locateResponse(endpointDescription, response), requestPayload))
                .map(response => [actions.fetch!.success.action(response, payload)]);
        };

        const epic = epicFromObservable<RootState>(readObservable, actions.fetch!);

        observablesObject.fetch = readObservable;
        epicsArray.push(epic);
    }

    if (apiDescription.fetchAll) {
        const endpointDescription = apiDescription.fetchAll;

        const readAllObservable = (payload: any) => {
            const url = getUrl(endpointDescription, payload);
            const requestPayload = getRequestPayload(endpointDescription, payload);
            const defaultRequestMethod = RequestMethod.GET;
            const RESTFunction = getRequestMethodFromEndpointDescription(
                endpointDescription,
                defaultRequestMethod,
                payload,
                RESTClient,
            );

            return RESTFunction(url, requestPayload).pipe<{items: any; entityReferences: any}, Array<Action<any>>>(
                map(response => {
                    return {
                        items: List(locateResponse(endpointDescription, response)).map(res =>
                            entityModel.fromAPI(res, requestPayload),
                        ),
                        entityReferences: getEntityReferences('fetchAll', response, entityRelations, payload),
                    };
                }),
                map(response => {
                    return [...response.entityReferences, actions.fetchAll!.success.action(response.items, payload)];
                }),
            );
        };

        const epic = epicFromObservable<RootState>(readAllObservable, actions.fetchAll!);

        observablesObject.fetchAll = readAllObservable;
        epicsArray.push(epic);
    }

    if (apiDescription.update) {
        const endpointDescription = apiDescription.update;

        const updateObservable = (payload: any) => {
            const url = getUrl(endpointDescription, payload);
            const requestPayload = getRequestPayload(endpointDescription, payload);

            return RESTClient.postAuthenticatedObservable(url, requestPayload).map(response => [
                actions.update!.success.action(
                    entityModel.fromAPI(locateResponse(endpointDescription, response), requestPayload),
                    payload,
                ),
            ]);
        };

        const epic = epicFromObservable<RootState>(updateObservable, actions.update!);

        observablesObject.update = updateObservable;
        epicsArray.push(epic);
    }

    if (apiDescription.delete) {
        const endpointDescription = apiDescription.delete;
        const defaultRequestMethod = RequestMethod.DELETE;

        const deleteObservable = (payload: any): Observable<Array<Action<any>>> => {
            const RESTFunction = getRequestMethodFromEndpointDescription(
                endpointDescription,
                defaultRequestMethod,
                payload,
                RESTClient,
            );
            const url = getUrl(endpointDescription, payload);
            const requestPayload = getRequestPayload(endpointDescription, payload);

            return handleDeleteConfirm(i18next.t('entities.deleteConfirm', {entityName})).pipe(
                flatMap(selection => {
                    if (selection === 1) {
                        return RESTFunction(url, requestPayload).pipe(
                            map(response => [
                                actions.delete!.success.action(response, payload),
                                onEntityInstanceDeleted(entityName),
                            ]),
                        );
                    }

                    return of([actions.delete!.aborted.action(payload)]);
                }),
            );
        };

        const epic = epicFromObservable<RootState>(deleteObservable, actions.delete!);

        observablesObject.delete = deleteObservable;
        epicsArray.push(epic);
    }

    const epics = combineEpics(...epicsArray);

    return {epics, observables: observablesObject};
};
