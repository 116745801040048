import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {type Components, type Theme, styled} from '@mui/material/styles';
import {type ListChildComponentProps, FixedSizeList} from 'react-window';
import MuiAutocomplete, {
    type AutocompleteProps as MuiAutocompleteProps,
    autocompleteClasses,
} from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import {Icon, type IconName, Text} from 'modern-famly/components/data-display';
import {type ColorKey} from 'modern-famly/theming';
import {useDataProps} from 'modern-famly/components/util';

type DisableClearable = boolean | undefined;
type FreeSolo = undefined;
type Multiple = undefined;
type AutocompleteProps<T extends SelectOption> = MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>;

export type BaseSelectProps<T extends SelectOption> = {
    /**
     * ID attribute of the input element.
     */
    id?: string;

    /**
     * If `true`, the `input` element is focused during the first mount.
     */
    autoFocus?: boolean;

    /**
     * Array of options.
     */
    options: T[];

    /**
     * If `true`, the component is shown in error state
     */
    error?: boolean;

    /**
     * If `true`, the component is disabled.
     */
    disabled?: AutocompleteProps<T>['disabled'];

    /**
     * If `true`, the input will take up the full width of its container.
     */
    fullWidth?: AutocompleteProps<T>['fullWidth'];

    /**
     * Text to display when there are no options.
     */
    noOptionsText?: string;

    /**
     * If `true`, the component is shown.
     */
    open?: AutocompleteProps<T>['open'];

    /**
     * The size of the component
     */
    size?: AutocompleteProps<T>['size'];

    /**
     * If true, the Popper content will be under the DOM hierarchy of the parent component.
     */
    disablePortal?: AutocompleteProps<T>['disablePortal'];

    /**
     * A placeholder text displayed in the `select` before the user selects a value.
     */
    placeholder?: string;

    /**
     * Option adornment for this component.
     */
    getOptionAdornment?: (option: T) => React.ReactNode;

    /**
     * Callback fired when the input value changes.
     *
     * @param {React.SyntheticEvent} event The event source of the callback.
     * @param {string} value The new value of the text input.
     * @param {string} reason Can be: `"input"` (user input), `"reset"` (programmatic change), `"clear"`.
     */
    onInputChange?: AutocompleteProps<T>['onInputChange'];

    /**
     * Callback fired when the popup requests to be opened.
     * Use in controlled mode (see open).
     *
     * @param {React.SyntheticEvent} event The event source of the callback.
     */
    onOpen?: AutocompleteProps<T>['onOpen'];

    /**
     * Callback fired when the popup requests to be closed.
     * Use in controlled mode (see open).
     *
     * @param {React.SyntheticEvent} event The event source of the callback.
     * @param {string} reason Can be: `"toggleInput"`, `"escape"`, `"selectOption"`, `"removeOption"`, `"blur"`.
     */
    onClose?: AutocompleteProps<T>['onClose'];

    /**
     * If `true`, hide the selected options from the list box.
     * @default false
     */
    filterSelectedOptions?: AutocompleteProps<T>['filterSelectedOptions'];

    /**
     * The input value.
     */
    inputValue?: AutocompleteProps<T>['inputValue'];

    /**
     * If `true`, the component is in a loading state.
     * This shows the `loadingText` in place of suggestions (only if there are no suggestions to show, e.g. `options` are empty).
     * @default false
     */
    loading?: AutocompleteProps<T>['loading'];

    /**
     * If `true`, the highlight can move to the input.
     * @default false
     */
    includeInputInList?: AutocompleteProps<T>['includeInputInList'];

    /**
     * If `true`, the portion of the selected suggestion that has not been typed by the user,
     * known as the completion string, appears inline after the input cursor in the textbox.
     * The inline completion string is visually highlighted and has a selected state.
     * @default false
     */
    autoComplete?: AutocompleteProps<T>['autoComplete'];

    /**
     * A function that groups the select options into categories or sections.
     *
     * When provided, this function is called for each option and returns a grouping key.
     * Options with the same returned key will be grouped together in the dropdown.
     * If you do use this prop, make sure that the options are also sorted with the same dimension that they are grouped by, otherwise, you will notice duplicate headers.
     *
     * @example
     * // Group options by the first letter of their label
     * groupBy={(option) => option.label.charAt(0).toUpperCase()}
     *
     * @param option - The individual select option to be grouped
     * @returns A string representing the group key, or undefined
     */
    groupBy?: AutocompleteProps<T>['groupBy'];

    /**
     * A function that determines the filtered options to be rendered on search.
     *
     * @param {T[]} options The options to render.
     * @param {object} state The state of the component.
     * @returns {T[]}
     */
    filterOptions?: AutocompleteProps<T>['filterOptions'];

    /**
     * Used to determine if the option represents the given value.
     * Uses strict equality by default.
     * ⚠️ Both arguments need to be handled, an option can only match with one value.
     *
     * @param {T} option The option to test.
     * @param {T} value The value to test against.
     * @returns {boolean}
     */
    isOptionEqualToValue?: AutocompleteProps<T>['isOptionEqualToValue'];

    /**
     * The maximum number of tags that will be visible when the component is in a collapsed state.
     * If the number of selected options exceeds this limit, the remaining options will be hidden
     * and a "+X" label will be displayed, where "X" is the number of hidden options.
     *
     * @default 2
     */
    limitTags?: AutocompleteProps<T>['limitTags'];
};

type MuiProps<T extends SelectOption, Multiple extends boolean | undefined> = Pick<
    MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'onChange' | 'renderInput' | 'value' | 'disableCloseOnSelect' | 'multiple' | 'renderTags' | 'sx'
>;

export const BaseSelect = <T extends SelectOption, Multiple extends boolean | undefined>({
    size,
    limitTags = 2,
    ...props
}: BaseSelectProps<T> & MuiProps<T, Multiple>) => {
    const {getOptionAdornment} = props;
    const dataProps = useDataProps(props);

    return (
        <MuiAutocomplete<T, Multiple, DisableClearable, FreeSolo>
            id={props.id}
            fullWidth={props.fullWidth}
            noOptionsText={props.noOptionsText}
            disableCloseOnSelect={props.disableCloseOnSelect}
            multiple={props.multiple}
            ListboxComponent={ListboxComponent}
            open={props.open}
            openOnFocus
            // When navigating with the list of options with a the arrow keys,
            // this prevents the list to wrap, e.g. going to the first option
            // in the list when pressing the "down" arrow key while being on the
            // last option. This is necessary as not all options are rendered at
            // the same time due to virtualization of the options list.
            disableListWrap
            size={size}
            disabled={props.disabled}
            onChange={props.onChange}
            disableClearable
            loading={props.loading}
            renderInput={props.renderInput}
            limitTags={limitTags}
            renderTags={props.renderTags}
            PaperComponent={Paper}
            renderGroup={({group, key, children}) => {
                return {
                    type: 'group',
                    groupTitle: group,
                    key,
                    groupOptions: children,
                } as GroupDataWithOptions as any;
            }}
            renderOption={(props, option, state) => {
                // Instead of returning a React.ReactNode from `renderOption`, we return an object
                // that can be used by react-window to virtualize the options list.
                //
                // The cast here is not necessary, but it's nice for clarifying how the data flows
                // through the `ListBoxComponent` and finally ends up in the `renderRow` function.
                return {
                    listItemAttributes: props,
                    option,
                    isSelected: state.selected,
                    getOptionAdornment,
                    size,
                } as OptionData as any;
            }}
            options={props.options}
            value={props.value}
            groupBy={props.groupBy}
            disablePortal={props.disablePortal}
            onInputChange={props.onInputChange}
            onOpen={props.onOpen}
            onClose={props.onClose}
            filterSelectedOptions={props.filterSelectedOptions}
            inputValue={props.inputValue}
            includeInputInList={props.includeInputInList}
            autoComplete={props.autoComplete}
            filterOptions={props.filterOptions}
            isOptionEqualToValue={props.isOptionEqualToValue}
            clearOnBlur
            {...dataProps}
            sx={props.sx}
        />
    );
};

export interface SelectOption {
    /**
     * The label of the option
     *
     * Should be used as the primary text to display
     */
    label: string;

    /**
     * Use this to provide additional context to the option
     *
     * Could for example be used to display the group the option
     * belongs to or similar information that provides context.
     */
    description?: string;

    /**
     * The value of the option
     *
     * Should be unique among the options
     */
    value: string;

    /**
     * The URL to an image to display as part of the option
     *
     * Note that imageUrl and icon are mutually exclusive
     */
    imageUrl?: string;

    /**
     * The icon to display as part of the option
     *
     * Note that imageUrl and icon are mutually exclusive
     */
    icon?: {
        name: IconName;
        color?: ColorKey;
        filled?: boolean;
    };
}

const OPTION_HEIGHT = 48;

/**
 * The component to display images in when `imageUrl` is passed
 * as part of an option.
 */
const OptionImage = styled('img')`
    border-radius: 50%;
    width: 20px;
    height: 20px;
`;

/*
|---------------------------------------------------------------------------------
| Virtualization
|---------------------------------------------------------------------------------
|
| To ensure great performance when the list of options grows, we use react-window
| to virtualize it.
|
*/

export type OptionData = {
    listItemAttributes: React.HTMLAttributes<HTMLLIElement>;
    option: SelectOption;
    isSelected: boolean;
    /**
     * Option adornment for this component
     */
    getOptionAdornment?: (option: SelectOption) => React.ReactNode;
    size?: BaseSelectProps<any>['size'];
};

export type GroupDataWithOptions = {
    type: 'group';
    groupTitle: string;
    key: string;
    groupOptions: OptionData[];
};

type GroupData = Pick<GroupDataWithOptions, 'type' | 'groupTitle'>;

const isSelectOption = (candidate: any): candidate is SelectOption => {
    return Boolean(candidate) && typeof candidate.label === 'string' && typeof candidate.value === 'string';
};

const isOptionData = (candidate: any): candidate is OptionData => {
    if (!candidate) {
        return false;
    }

    if (
        !candidate.listItemAttributes ||
        !isSelectOption(candidate.option) ||
        typeof candidate.isSelected !== 'boolean'
    ) {
        return false;
    }

    return true;
};

const isOptionGroupData = (candidate: any): candidate is GroupDataWithOptions => {
    if (!candidate) {
        return false;
    }

    if (!candidate.type || candidate.type !== 'group' || typeof candidate.groupTitle !== 'string') {
        return false;
    }

    return true;
};

const DEFAULT_OPTION_PADDING_LEFT = 16;

const getLeftPadding = (option: SelectOption) => {
    // We want a bit less padding for options with an icon or image
    if (option.icon || option.imageUrl) {
        return '8px';
    }

    return `${DEFAULT_OPTION_PADDING_LEFT}px`;
};

/**
 * Renders a single option row in the Select's list
 */
function renderRow(props: ListChildComponentProps) {
    const {data, index, style} = props;

    if (!data || !Array.isArray(data)) {
        return null;
    }

    const targetData = data[index];

    // targetData can be a group header if 'groupBy' prop is used on the MUI autocomplete component
    // We need to check if we are dealing with a group header so we can render it separately.

    if (isOptionGroupData(targetData)) {
        return (
            <li
                style={{
                    ...style,
                    paddingLeft: `${DEFAULT_OPTION_PADDING_LEFT / 2}px`,
                    height: `${OPTION_HEIGHT / 2}px`,
                    marginTop: '22px',
                    boxSizing: 'border-box',
                }}
                key={targetData.groupTitle}
                data-e2e-class="select-group-header"
            >
                <Text variant="body" emphasized color="n300">
                    {targetData.groupTitle}
                </Text>
            </li>
        );
    }

    if (!isOptionData(targetData)) {
        return null;
    }

    const {listItemAttributes, option, isSelected, getOptionAdornment} = targetData;

    const inlineStyle: React.CSSProperties = {
        ...style,
        top: style.top,
        paddingLeft: getLeftPadding(option),
    };

    return (
        <li {...listItemAttributes} style={inlineStyle} key={option.value} data-e2e-class="select-option">
            <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
                <Stack direction="row" gap={2} alignItems="center" overflow="hidden">
                    {option.imageUrl ? <OptionImage src={option.imageUrl} /> : null}
                    {option.icon ? (
                        <Icon size={20} name={option.icon.name} color={option.icon.color} filled={option.icon.filled} />
                    ) : null}
                    <Box overflow="hidden">
                        <Text variant={targetData.size === 'compact' ? 'body-small' : 'body'} ellipsis>
                            {option.label}
                        </Text>
                        {option.description ? (
                            <Text variant="micro" color="n300" ellipsis>
                                {option.description}
                            </Text>
                        ) : null}
                    </Box>
                </Stack>
                {getOptionAdornment || isSelected ? (
                    <Stack direction="row" gap={1} alignItems="center">
                        {getOptionAdornment?.(option)}
                        {isSelected ? <Icon name="check" size={20} color="p400"></Icon> : null}
                    </Stack>
                ) : null}
            </Stack>
        </li>
    );
}

/**
 * Renders the <ul /> element that contains all of the Select's options
 *
 * Uses react-window to ensure good performance once the list grows
 */

export const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>((props, ref) => {
    const {children, ...listBoxPropsWithoutChildren} = props;

    // Flatten the grouped data for react-window
    const optionsWithGroups = React.useMemo(() => {
        return (children as OptionData[] | GroupDataWithOptions[]).reduce<(OptionData | GroupData)[]>((acc, item) => {
            // check if the item is a group, if true then add it to the array as a separate option
            // so it can get picked up in the renderRow function and rendered in a different way.
            if (isOptionGroupData(item)) {
                acc.push({
                    type: 'group',
                    groupTitle: item.groupTitle,
                });

                // add all of the normal options that got sent together with the group title to the array now so they can be rendered after the group title
                acc.push(...item.groupOptions);
            } else {
                // we are dealing with normal options without groups, add them to the array without changes
                acc.push(item);
            }

            return acc;
        }, []);
    }, [children]);

    const itemCount = optionsWithGroups?.length || 0;

    const height = React.useMemo(() => {
        // Adjust height calculation to account for group headers
        const groupHeadersLength = optionsWithGroups.filter(
            item => isOptionGroupData(item) && item.type === 'group',
        ).length;
        const regularItems = itemCount - groupHeadersLength;

        const totalHeight = regularItems * OPTION_HEIGHT + groupHeadersLength;
        return Math.min(totalHeight, 6 * OPTION_HEIGHT);
    }, [optionsWithGroups, itemCount]);

    const selectedItemIndex = React.useMemo(() => {
        return optionsWithGroups?.findIndex(option => {
            // this is relevant only if we are dealing with an option and not a group
            if (isOptionData(option)) {
                return option?.isSelected;
            }

            return false;
        });
    }, [optionsWithGroups]);

    const listRef = React.useRef<FixedSizeList>(null);

    /**
     * if there's a selected item, scroll to it.
     */
    React.useEffect(() => {
        if (selectedItemIndex) {
            listRef.current?.scrollToItem(selectedItemIndex, 'smart');
        }
    }, [listRef, selectedItemIndex]);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={listBoxPropsWithoutChildren}>
                <FixedSizeList
                    itemData={optionsWithGroups}
                    height={height}
                    width="100%"
                    ref={listRef}
                    outerElementType={OuterElementType}
                    innerElementType={InnerElementType}
                    itemSize={OPTION_HEIGHT}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </FixedSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

const OuterElementContext = React.createContext({});

const InnerElementType = styled('ul')`
    margin: 0;
`;

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

/**
 * The wrapper component in which options are displayed
 */
const Paper = styled(Box)`
    background-color: ${props => props.theme.modernFamlyTheme.colorPalette.n0};
    border: 1px solid ${({theme}) => theme.modernFamlyTheme.colorPalette.n100};
    border-radius: 8px;
    box-shadow: ${({theme}) => theme.modernFamlyTheme.elevation[1]};
    margin-top: ${({theme}) => theme.modernFamlyTheme.spacing(0.5)};
    padding-top: 0;

    .MuiAutocomplete-option {
        min-height: ${OPTION_HEIGHT}px !important;

        &:not(:last-child) {
            border-bottom: 1px solid ${({theme}) => theme.modernFamlyTheme.colorPalette.n100};
        }
    }

    .MuiAutocomplete-option.Mui-focused {
        background-color: ${props => props.theme.modernFamlyTheme.colorPalette.p50} !important;
    }

    .MuiAutocomplete-option[aria-selected='true'] {
        background-color: ${props => props.theme.modernFamlyTheme.colorPalette.p50} !important;
    }
`;

/**
 * The loading spinner that should be displayed when the component is in a loading state
 */
export const LoadingAdornment = ({size}: {size: 'compact' | 'regular'}) => {
    return (
        <CircularProgress
            size={size === 'regular' ? 24 : 20}
            sx={theme => ({
                color: theme.modernFamlyTheme.colorPalette.p400,
            })}
        />
    );
};

/*
|------------------------------------------------------------------------------
| MUI Theming
|------------------------------------------------------------------------------
*/
declare module '@mui/material/Autocomplete' {
    interface AutocompletePropsSizeOverrides {
        small: false;
        medium: false;

        regular: true;
        compact: true;
    }
}

export const SelectThemeConfiguration: Components<Theme>['MuiAutocomplete'] = {
    styleOverrides: {
        input: ({theme, ownerState}) => ({
            fontSize:
                ownerState.size === 'compact'
                    ? theme.typography['body-small'].fontSize
                    : theme.typography.body.fontSize,

            '&.MuiOutlinedInput-input': {
                padding: theme.modernFamlyTheme.spacing(1, 0),
            },
        }),
        inputRoot: ({theme, ownerState}) => ({
            minHeight: ownerState.size === 'compact' ? '36px' : '48px',
            paddingLeft: theme.modernFamlyTheme.spacing(3),
            paddingTop:
                ownerState.size === 'compact'
                    ? theme.modernFamlyTheme.spacing(1.25)
                    : theme.modernFamlyTheme.spacing(2.75),
            paddingBottom:
                ownerState.size === 'compact'
                    ? theme.modernFamlyTheme.spacing(1.25)
                    : theme.modernFamlyTheme.spacing(2.75),

            // When `multiple` is `true`, ensures that pills
            // shrink correctly inside the input field when
            // it's unfocused
            ...(ownerState.multiple === true
                ? {
                      '&:not(.Mui-focused)': {
                          flexWrap: ownerState.multiple === true ? 'nowrap' : 'wrap',

                          '& .MuiChip-root': {
                              flexShrink: '1',
                              flexGrow: '0',
                              minWidth: '0',
                          },
                      },
                  }
                : {}),

            '& .MuiInputAdornment-positionStart': {
                marginRight: theme.modernFamlyTheme.spacing(1),
            },

            [`&.Mui-disabled .${autocompleteClasses.endAdornment}`]: {
                color: theme.modernFamlyTheme.colorPalette.n300,
            },

            '&.Mui-disabled .MuiInputAdornment-positionStart': {
                opacity: 0.3,
            },
            '&.MuiInputBase-adornedStart': {
                gap: theme.modernFamlyTheme.spacing(1),
            },
            '.MuiAutocomplete-tag': {
                margin: 0,
                height: '24px',
                display: 'inline-flex',
                alignItems: 'center',
                padding: theme.modernFamlyTheme.spacing(0, 2.5, 0, 2),
                fontSize: theme.typography['body-small'].fontSize,
                backgroundColor: theme.modernFamlyTheme.colorPalette.n100,
                borderRadius: '24px',
            },
            '.MuiOutlinedInput-input': {
                padding: '0 !important',

                // When `multiple` is `true`, don't show the input field
                // when one option or more options are selected. We can't use
                // `display: none` for this as the input field would never
                // recieve focus. Instead we shrink the width to 0.
                ...(ownerState.multiple === true
                    ? {
                          '&:not(:focus)': {
                              minWidth: (ownerState.value?.length ?? 0) > 0 ? '0px !important' : 'initial',
                              width: (ownerState.value?.length ?? 0) > 0 ? '0px !important' : 'initial',
                              flexGrow: (ownerState.value?.length ?? 0) > 0 ? '0 !important' : 'initial',
                          },
                      }
                    : {}),
            },
        }),
        listbox: () => ({
            padding: 0,
        }),
        // these styles will only be applied to the StandaloneMultiSelect component
        // we are applying custom styles for the groupLabel in the renderRow function
        groupLabel: ({theme}) => ({
            paddingLeft: 0,
            lineHeight: 'normal',
            marginTop: theme.modernFamlyTheme.spacing(2),
            paddingTop: theme.modernFamlyTheme.spacing(2),
            paddingBottom: theme.modernFamlyTheme.spacing(1),
            fontSize: theme.typography.body.fontSize,
        }),
    },
};
