import React from 'react';
import {QRCodeSVG} from 'qrcode.react';
import styled from 'styled-components';
import {useTheme, Text} from 'modern-famly';

import {Base, Flex} from 'web-app/react/components/layout/layout';
import Spinner from 'web-app/react/components/loading/spinner/spinner';
import ProfileImage from 'web-app/react/components/profile-image/profile-image';
import * as Breakpoints from 'web-app/styleguide/breakpoint-helpers';
import {hslStringToHex} from 'web-app/util/hsl-helpers';
import translate from 'signin-app/helpers/translate';
import {SettingsDropdown} from 'signin-app/components/settings-dropdown';
import {useTypedSelector} from 'signin-app/components/hooks';
import * as WhitelabelSelectors from 'signin-app/whitelabel/selectors';
import {useResilientQuery} from '@famly/stat_apollo-query';

import * as Queries from './queries';
import {useGenerateQrValue, useGenerateSecret} from './hooks';
import {type GetInstitutionDataQuery} from './__generated__/queries.api-types';

export const QrScreen = () => {
    const {data: institutionData} = useResilientQuery<GetInstitutionDataQuery>(Queries.GetInstitutionData);

    const institution = React.useMemo(() => {
        if (institutionData && institutionData.institutions.length > 0) {
            // For the sign in user, the query will return only one institution
            return institutionData.institutions[0];
        }
        return undefined;
    }, [institutionData]);

    return (
        <StyledQRView direction="column" align="center" fullHeight fullWidth data-e2e-id="QR-generator-view">
            <LogoutContainer>
                <SettingsDropdown showWarning siteName={institution?.title} />
            </LogoutContainer>
            <InstitutionContainer>
                <ProfileImage
                    src={institution?.profileImage?.url}
                    size={80}
                    placeholderSrc="img/empty-institution.jpg"
                />
                <Text variant="h6" color="n0" emphasized>
                    {institution?.title}
                </Text>
            </InstitutionContainer>
            <TextContainer>
                <Text variant="h5" marginBottom="10px" color="n0">
                    {translate('qrCodeExplanation')}
                </Text>
            </TextContainer>
            <QRCode institutionId={institution?.institutionId} />
        </StyledQRView>
    );
};

export const QRCode: React.FC<{institutionId: string | undefined}> = ({institutionId}) => {
    const modernFamlyTheme = useTheme();
    const [secret, setSecret] = React.useState('');
    const [qrValue, setQrValue] = React.useState('');

    const whitelabelConfiguration = useTypedSelector(state => WhitelabelSelectors.whiteLabelConfiguration(state));
    const customP500 = whitelabelConfiguration.p500;

    useGenerateSecret(institutionId, setSecret);
    useGenerateQrValue(institutionId, setQrValue, secret);

    return (
        <QRCodeContainer>
            {!institutionId || !qrValue ? (
                <Spinner color="white" centered />
            ) : (
                <QRCodeSVG
                    value={qrValue}
                    fgColor={customP500 ? customP500 : hslStringToHex(modernFamlyTheme.colorPalette.p500)}
                    size={160}
                    data-e2e-id="qr-code"
                    data-e2e-qrcode-value={qrValue}
                />
            )}
        </QRCodeContainer>
    );
};

const StyledQRView = styled(Flex)`
    position: relative;
    background-image: url("data:image/svg+xml,%3Csvg width='1194' height='834' viewBox='0 0 1194 834' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg style='mix-blend-mode:overlay' opacity='0.2'%3E%3Cpath d='M1239.38 2.24008C361.248 98.6683 320.29 901.326 477.249 875.07C634.209 848.813 231.236 257.223 -252.813 471.442' stroke='%23F4EDFC' stroke-width='11' stroke-linecap='round'/%3E%3C/g%3E%3C/svg%3E");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
    min-height: 100vh;
`;
const LogoutContainer = styled(Flex)`
    position: absolute;
    right: 0;
    top: 0;
    padding: 20px;

    ${Breakpoints.tabletLandscapeAndLarger`
        padding: 40px;
    `};
`;
const InstitutionContainer = styled(Base)`
    max-width: 310px;
    text-align: center;
    margin-top: 70px;
    margin-bottom: 75px;
`;
const QRCodeContainer = styled(Base)`
    box-sizing: border-box;
    background-color: ${props => props.theme.mf.colorPalette.n0};
    padding: 43px;
    border-radius: 12px;
    width: 246px;
    height: 250px;
`;
export const TextContainer = styled(Base)`
    max-width: 310px;
    text-align: center;
    margin-bottom: 24px;
`;
