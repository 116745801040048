/* eslint-disable consistent-return */
import React from 'react';
import type CSSType from 'csstype';
import styled, {css, type ThemedStyledProps} from 'styled-components';
import {type ColorPalette} from 'modern-famly';

import {
    type SingleSpacingDescription,
    type SpacingDescription,
    getSpacingStringFromDescription,
} from 'web-app/styleguide/spacing';
import {type ITheme} from 'web-app/styleguide/themes/model';
import {media} from 'web-app/styleguide/utils';
import {Breakpoint} from 'web-app/styleguide/breakpoints';
import {breakPointToCSSFunction} from 'web-app/styleguide/breakpoint-helpers';
import {hasValue} from '@famly/stat_ts-utils_has-value';
import {type ElevationProps, elevationCSS} from 'web-app/styleguide/elevations';

// It would be neat if we didn't have to extend react props,
// but we have to due to this https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30117.
// The only alternative I can think of before someone comes up with a solution to the above problem is to make the Base
// component generic so it has a change to specify the correct React.ClassAttributes and React.HTMLAttributes
type HTMLProps<T = HTMLElement> = Pick<
    React.DetailedHTMLProps<React.HTMLAttributes<T>, T>,
    'onClick' | 'id' | 'className' | 'onFocus' | 'title' | 'tabIndex' | 'onTouchEnd'
>;

export interface MarginProps {
    margin?: StyleProp<SpacingDescription>;
    marginLeft?: StyleProp<SingleSpacingDescription>;
    marginRight?: StyleProp<SingleSpacingDescription>;
    marginTop?: StyleProp<SingleSpacingDescription>;
    marginBottom?: StyleProp<SingleSpacingDescription>;
}

interface BaseLayoutProps extends HTMLProps {
    className?: string;

    background?: keyof ColorPalette;
    radius?: StyleProp<SingleSpacingDescription>;

    padding?: StyleProp<SpacingDescription>;
    paddingLeft?: StyleProp<SingleSpacingDescription>;
    paddingRight?: StyleProp<SingleSpacingDescription>;
    paddingTop?: StyleProp<SingleSpacingDescription>;
    paddingBottom?: StyleProp<SingleSpacingDescription>;

    grow?: number | 'inherit' | 'initial' | 'unset';
    shrink?: number | 'inherit' | 'initial' | 'unset';
    basis?: StyleProp<CSSType.Property.FlexBasis<string>>;
    textAlign?: StyleProp<CSSType.Property.TextAlign>;
    visibility?: 'hidden';
    alignSelf?: CSSType.Property.AlignSelf;
    whiteSpace?: CSSType.Property.WhiteSpace;
    css?: any;
    overflow?: StyleProp<CSSType.Property.Overflow>;
    zIndex?: number;
    boxSizing?: 'content-box' | 'border-box';
    position?: 'static' | 'relative' | 'absolute';
    opacity?: number;
    minHeight?: CSSType.Property.Height<number | string>;
    minWidth?: CSSType.Property.Width<number | string>;
    maxWidth?: CSSType.Property.Width<number | string>;

    fullWidth?: boolean;
    fullHeight?: boolean;
    height?: StyleProp<CSSType.Property.Height<string>>;
    maxHeight?: StyleProp<CSSType.Property.Height<string>>;
    ellipsis?: boolean;
    relative?: boolean;

    flex?: CSSType.Property.Flex<string | number>;

    showOnMobile?: boolean;
    hideOnMobile?: boolean;

    pointerEvents?: StyleProp<CSSType.Property.PointerEvents>;
    cursor?: CSSType.Property.Cursor;
}

export type BaseProps = BaseLayoutProps & MarginProps & ElevationProps;

const stringIsBreakpoint = (s: string): s is Breakpoint => hasValue(Breakpoint[s]);

type RawProp<T> = T;
type ResponsiveProp<T> = {[p in Breakpoint]?: T} & {base?: T};
export type StyleProp<T> = ResponsiveProp<T> | RawProp<T> | undefined;

type CSSInput = SpacingDescription | undefined;

const propsIsResponsive = (prop: StyleProp<CSSInput>): prop is ResponsiveProp<CSSInput> =>
    typeof prop === 'object' && Object.keys(prop).some(k => stringIsBreakpoint(k) || k === 'base');

/*
    Usage:
        1. Either pass in the raw value for the corresponding CSS rule
        2. .. or pass in an object like {[Breakpoint]: value}.
            2. (I) You can also add a key "base" which will always be active until a breakpoint becomes active and the specified value takes over
*/
type StyleProps<T> = ThemedStyledProps<T, ITheme>;
export function stylePropToCSS<T = BaseProps>(
    propsToStyleProp: (props: StyleProps<T>) => StyleProp<CSSInput>,
    cssProp: string,
) {
    return (props: StyleProps<T>) => {
        const prop = propsToStyleProp(props);
        if (!hasValue(prop)) {
            return '';
        }
        if (propsIsResponsive(prop)) {
            return Object.keys(prop).map(breakpoint => {
                const breakpointValue = prop[breakpoint];
                if (!hasValue(breakpointValue)) {
                    return '';
                }
                if (stringIsBreakpoint(breakpoint)) {
                    return breakPointToCSSFunction(breakpoint)`${cssProp}: ${getSpacingStringFromDescription(
                        breakpointValue,
                        props.theme,
                    )};`;
                } else if (breakpoint === 'base') {
                    return css`
                        ${cssProp}: ${getSpacingStringFromDescription(breakpointValue, props.theme)};
                    `;
                }
            });
        }
        return css`
            ${cssProp}: ${getSpacingStringFromDescription(prop, props.theme)};
        `;
    };
}

// The unused props argument is there for type checking
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const marginPropsToCSS = (props: MarginProps) => css<MarginProps>`
    ${stylePropToCSS(props => props.margin, 'margin')}
    ${stylePropToCSS(props => props.marginBottom, 'margin-bottom')}
    ${stylePropToCSS(props => props.marginTop, 'margin-top')}
    ${stylePropToCSS(props => props.marginRight, 'margin-right')}
    ${stylePropToCSS(props => props.marginLeft, 'margin-left')}
`;

/**
 * @deprecated Use the `Box` component from `modern-famly` instead
 */
export const Base = styled.div<BaseProps>`
    ${marginPropsToCSS}
    ${stylePropToCSS(props => props.padding, 'padding')}
    ${stylePropToCSS(props => props.paddingBottom, 'padding-bottom')}
    ${stylePropToCSS(props => props.paddingTop, 'padding-top')}
    ${stylePropToCSS(props => props.paddingRight, 'padding-right')}
    ${stylePropToCSS(props => props.paddingLeft, 'padding-left')}
    ${stylePropToCSS(props => props.pointerEvents, 'pointer-events')}

    ${stylePropToCSS(
        props => (props.background ? props.theme.mf.colorPalette[props.background] : undefined),
        'background',
    )}
    ${stylePropToCSS(props => props.radius, 'border-radius')}

    ${elevationCSS}


    ${props =>
        props.grow
            ? css`
                  flex-grow: ${props.grow};
              `
            : ''}
    ${props =>
        props.shrink !== undefined
            ? css`
                  flex-shrink: ${props.shrink};
              `
            : ''}

    ${stylePropToCSS(props => props.basis, 'flex-basis')}

    ${stylePropToCSS(props => props.textAlign, 'text-align')}
    ${props =>
        props.whiteSpace
            ? css`
                  white-space: ${props.whiteSpace};
              `
            : ''}
    ${props =>
        props.alignSelf
            ? css`
                  align-self: ${props.alignSelf};
              `
            : ''}

    ${props => (props.css ? props.css : '')}

    ${props =>
        props.fullWidth
            ? css`
                  width: 100%;
              `
            : ''}
    ${props =>
        props.fullHeight
            ? css`
                  height: 100%;
              `
            : ''}

    ${stylePropToCSS(props => props.height, 'height')}

    ${stylePropToCSS(props => props.maxHeight, 'max-height')}

    ${stylePropToCSS(props => props.overflow, 'overflow')}

    ${props =>
        props.flex
            ? css`
                  flex: ${props.flex};
              `
            : ''}

    ${props =>
        props.visibility
            ? css`
                  visibility: ${props.visibility};
              `
            : ''}

    ${props =>
        props.ellipsis
            ? css`
                  text-overflow: ellipsis;
              `
            : ''}

    ${props =>
        props.relative
            ? css`
                  position: relative;
              `
            : ''}

    ${props =>
        props.zIndex
            ? css`
                  z-index: ${props.zIndex};
              `
            : ''}

    ${props =>
        props.boxSizing
            ? css`
                  box-sizing: ${props.boxSizing};
              `
            : ''}

    ${props =>
        props.position
            ? css`
                  position: ${props.position};
              `
            : ''}

    ${props =>
        hasValue(props.opacity)
            ? css`
                  opacity: ${props.opacity};
              `
            : ''}

    ${props =>
        hasValue(props.minWidth)
            ? css`
                  min-width: ${formatMaybeNumber(props.minWidth, 'px')};
              `
            : ''}

    ${props =>
        hasValue(props.minHeight)
            ? css`
                  min-height: ${formatMaybeNumber(props.minHeight, 'px')};
              `
            : ''}

    ${props =>
        hasValue(props.maxWidth)
            ? css`
                  max-width: ${formatMaybeNumber(props.maxWidth, 'px')};
              `
            : ''}

    ${props =>
        hasValue(props.cursor)
            ? css`
                  cursor: ${props.cursor};
              `
            : ''}

    ${props => (props.hideOnMobile ? media.mobile`display: none;` : '')}
    ${props => (props.showOnMobile ? media.notMobile`display: none;` : '')}
`;

const formatMaybeNumber = (value: number | string, postfix: string) =>
    typeof value === 'number' ? `${value}${postfix}` : value;

export const InlineBase = styled(({...rest}) => <Base as="span" {...rest} />)<BaseProps>``;

export interface FlexProps extends BaseProps {
    direction?: StyleProp<CSSType.Property.FlexDirection>;
    align?: StyleProp<CSSType.Property.AlignItems>;
    wrap?: StyleProp<CSSType.Property.FlexWrap>;
    justify?: StyleProp<CSSType.Property.JustifyContent>;
    childSpacing?: SingleSpacingDescription;
}

/**
 * @deprecated Use the `Stack` component from `modern-famly` instead
 */
export const Flex = styled(Base)<FlexProps>`
    display: flex;
    ${stylePropToCSS(props => props.justify, 'justify-content')}
    ${stylePropToCSS(props => props.wrap, 'flex-wrap')}
    ${stylePropToCSS(props => props.align, 'align-items')}
    ${stylePropToCSS(props => props.direction, 'flex-direction')}

    ${props =>
        props.childSpacing &&
        css`
            & > * {
                :not(:last-child) {
                    ${props.direction === 'column'
                        ? 'margin-bottom'
                        : 'margin-right'}: ${getSpacingStringFromDescription(props.childSpacing!, props.theme)};
                }
            }
        `}
`;

export const InlineFlex = styled(Flex)<FlexProps>`
    display: inline-flex;
`;
