import {hasValue} from '@famly/stat_ts-utils_has-value';

export const exhaustiveCheck = (x: never) => {
    console.warn('Exhaustive check caught following value:', x);
};

export {
    /**
     * @deprecated import `hasValue` directly from '@famly/stat_ts-utils_has-value
     */
    hasValue,
};

export type NonEmptyArray<T> = [T, ...T[]];

export function isNonEmpty<T>(arr: T[] | NonEmptyArray<T>): arr is NonEmptyArray<T> {
    return arr.length > 0;
}
